<template>
    <div class="pdf">
        <Button-component buttonText="Распечатать заказ" class="pdf-button" @click="exportPDF"/>
        <div class="pdf-box">
            <div ref="pdfOrder" class="order-pdf">
                <div :class="order.type === 'Памятник' ? 'monument__one-page' : ''">
                    <header class="pdf-header">
                        <div class="pdf-header__logo-container">
                            <img :src="image.logo" alt="Март" class="pdf-header__logo-container__logo">
                        </div>
                        <div class="pdf-header__text-container">
                            <span class="pdf-header__text" v-if="order.creatorContacts?.fullname"><b>Исполнитель</b>{{order.creatorContacts?.fullname}}</span>
                            <span class="pdf-header__text" v-if="order.information.manager"><b>Менеджер</b>{{order.information.manager}}</span>
                            <span class="pdf-header__text" v-if="order.letter"><b>Пункт приема заказа</b>{{order.letter}}</span>
                        </div>
                    </header>
                    <main class="pdf-main">
                        <section class="pdf-hero">
                            <div class="pdf-col">
                                <h1 v-if="order.type === 'Благоустройство могил'" class="pdf-hero__title">Наряд-заказ на благоустройство могилы<span>{{order.number}}</span></h1>
                                <h1 v-if="order.type === 'Памятник'" class="pdf-hero__title">Наряд-заказ на гранитное изделие <span>{{order.number}}</span></h1>
                            </div>
                            <div class="pdf-col">
                                <span class="pdf-hero__date"><b>Дата приёма</b>{{new Date(order?.dates.startAt).toLocaleDateString()}}</span>
                                <span class="pdf-hero__date"><b>Дата выполнения</b>{{new Date(order?.dates.endAt).toLocaleDateString()}}</span>
                            </div>
                        </section>
                        <section v-if="order.type === 'Благоустройство могил'" class="pdf-information">
                            <div class="pdf-col">
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Ф.И.О. заказчика</span>
                                    <span class="pdf-information__text">{{order?.information.client}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Адрес</span>
                                    <span class="pdf-information__text">{{order?.information.clientAddress}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Ф.И.О. покойного</span>
                                    <span class="pdf-information__text">{{order?.information.deceased}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Место установки</span>
                                    <span class="pdf-information__text">{{order?.information.cemetery}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Участок</span>
                                    <span class="pdf-information__text">{{order?.information.graveDistrict}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Ряд</span>
                                    <span class="pdf-information__text">{{order?.information.graveRow}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Место</span>
                                    <span class="pdf-information__text">{{order?.information.gravePlace}}</span>
                                </div>
                            </div>
                        </section>
                        <section v-if="order.type === 'Памятник'" class="pdf-information__one-page">
                            <div class="pdf-col">
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Ф.И.О. заказчика</span>
                                    <span class="pdf-information__text">{{order?.information.client}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Телефон</span>
                                    <span class="pdf-information__text">{{order?.information.clientPhone}}</span>
                                </div>
                                <div v-if="order?.information.clientAddress" class="pdf-information__item">
                                    <span class="pdf-information__title">Адрес</span>
                                    <span class="pdf-information__text">{{order?.information.clientAddress}}</span>
                                </div>
                            </div>
                            <div class="pdf-col deceased">
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Ф.И.О. покойного</span>
                                    <span class="pdf-information__text">{{order?.information.deceased}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Дата рождения</span>
                                    <span class="pdf-information__text">{{new Date(order?.information.birthDate).toLocaleDateString()}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Дата смерти</span>
                                    <span class="pdf-information__text">{{new Date(order?.information.deathDate).toLocaleDateString()}}</span>
                                </div>
                            </div>
                            <div class="pdf-col cemetery">
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Место установки</span>
                                    <span class="pdf-information__text">{{order?.information.cemetery}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Участок</span>
                                    <span class="pdf-information__text">{{order?.information.graveDistrict}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Ряд</span>
                                    <span class="pdf-information__text">{{order?.information.graveRow}}</span>
                                </div>
                                <div class="pdf-information__item">
                                    <span class="pdf-information__title">Место</span>
                                    <span class="pdf-information__text">{{order?.information.gravePlace}}</span>
                                </div>
                            </div>
                        </section>
                        <section v-if="order.type === 'Благоустройство могил'" class="pdf-service">
                            <table class="pdf-table">
                                <thead class="pdf-table__head">
                                    <tr class="pdf-table__item">
                                        <th class="pdf-table__title pdf-table__title_7">№</th>
                                        <th class="pdf-table__title pdf-table__title_33">Наименование услуги</th>
                                        <th class="pdf-table__title pdf-table__title_15">Размер</th>
                                        <th class="pdf-table__title pdf-table__title_15">Ед. изм.</th>
                                        <th class="pdf-table__title pdf-table__title_15">Цена за ед., ₽</th>
                                        <th class="pdf-table__title pdf-table__title_15">Сумма, ₽</th>
                                    </tr>
                                </thead>
                                <tbody class="pdf-table__body">
                                    <tr class="pdf-table__item" v-for="service, index in order?.services">
                                        <td class="pdf-table__text">{{index + 1}}</td>
                                        <td class="pdf-table__text">{{service.title}}</td>
                                        <td class="pdf-table__text">{{service.quantity}}</td>
                                        <td class="pdf-table__text">{{service.measurement}}</td>
                                        <td class="pdf-table__text">{{new Intl.NumberFormat('ru-RU').format(service.cost)}}</td>
                                        <td class="pdf-table__text">{{new Intl.NumberFormat('ru-RU').format(service.price)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                        <section v-if="order.type === 'Памятник'" class="pdf-service__one-page">
                            <table>
                                <thead>
                                    <tr>
                                        <th colspan="2">Наименование услуги</th>
                                        <th>Размер</th>
                                        <th>Количество</th>
                                        <th>Сумма, ₽</th>
                                    </tr>
                                </thead>
                                <tbody v-for="servicesType, key in servicesTypes" :key="key">
                                    <tr class="table-header">
                                        <td colspan="4">{{servicesType}}</td>
                                        <td>{{getCurrentTypePrice(servicesType)}}</td>
                                    </tr>
                                    <tr v-for="service, index in getCurrentServiceType(servicesType)" :key="index">
                                        <td>{{index + 1}}</td>
                                        <td>{{service.title}} <span>{{getCurrentServiceProperties(service)}}</span></td>
                                        <td>{{service.size ? service.size : '-'}}</td>
                                        <td>{{service.quantity ? service.quantity : '1'}}</td>
                                        <td>{{new Intl.NumberFormat('ru-RU').format(service.price)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                        <section class="pdf-description" v-if="order.type === 'Благоустройство могил' && (order?.moreServices || order?.comment)">
                            <div class="pdf-col">
                                <h2 class="pdf-description__title" v-if="order?.moreServices">Дополнительные услуги</h2>
                                <p class="pdf-description__text">{{order?.moreServices}}</p>
                            </div>
                            <div class="pdf-col">
                                <h2 class="pdf-description__title" v-if="order?.comment">Комментарии к заказу</h2>
                                <p class="pdf-description__text">{{order?.comment}}</p>
                            </div>
                        </section>
                        <section v-if="order.type === 'Благоустройство могил'" class="pdf-payment">
                            <div class="pdf-col">
                                <div class="pdf-payment__item">
                                    <span class="pdf-payment__title pdf-payment__title_min">Итоговая сумма заказа</span>
                                    <span class="pdf-payment__number pdf-payment__number_gold">{{order?.price.final}}</span>
                                </div>
                                <div class="pdf-payment__item">
                                    <span class="pdf-payment__title pdf-payment__title_min">Тип оплаты</span>
                                    <span class="pdf-payment__number pdf-payment__number_small">{{prepay === 0 ? 'без предоплаты' : remains === 0 ? 'полный расчёт' : 'авансовый платёж'}}</span>
                                </div>
                                <div class="pdf-payment__item" v-if="order?.payments">
                                    <span class="pdf-payment__title pdf-payment__title_min">Аванс в размере</span>
                                    <span class="pdf-payment__number">{{prepay}}</span>
                                </div>
                                <div class="pdf-payment__item">
                                    <span class="pdf-payment__title pdf-payment__title_min">Остаток</span>
                                    <span class="pdf-payment__number">{{remains}}</span>
                                </div>
                            </div>
                            <div class="pdf-col">
                                <h2 class="pdf-payment__title">Подпись заказчика</h2>
                                <img class="pdf-payment__image" :src="order?.signImage" alt="" width="280" height="300">
                            </div>
                        </section>
                        <section v-if="order.type === 'Памятник'" class="pdf-bottom__one-page">
                            <div class="pdf-col">
                                <div class="pdf-payment__item">
                                    <span class="pdf-payment__title pdf-payment__title_min">Срок окончания работ</span>
                                    <span class="pdf-payment__number pdf-payment__number_small">{{new Date(order?.dates.endAt).toLocaleDateString()}}</span>
                                </div>
                                <div class="pdf-payment__item">
                                    <span class="pdf-payment__title pdf-payment__title_min">Итоговая сумма заказа</span>
                                    <span class="pdf-payment__number pdf-payment__number_gold">{{order?.price.final}} ₽</span>
                                </div>
                                <div class="pdf-payment__item">
                                    <span class="pdf-payment__title pdf-payment__title_min">Тип оплаты</span>
                                    <span class="pdf-payment__number pdf-payment__number_small">{{prepay === 0 ? 'без предоплаты' : remains === 0 ? 'полный расчёт' : 'авансовый платёж'}}</span>
                                </div>
                                <div class="pdf-payment__item" v-if="order?.payments">
                                    <span class="pdf-payment__title pdf-payment__title_min">Аванс в размере</span>
                                    <span class="pdf-payment__number">{{prepay}} ₽</span>
                                </div>
                                <div class="pdf-payment__item">
                                    <span class="pdf-payment__title pdf-payment__title_min">Остаток</span>
                                    <span class="pdf-payment__number">{{remains}} ₽</span>
                                </div>
                            </div>
                            <div class="pdf-col">
                                <h2 class="pdf-description__title" v-if="order?.moreServices">Дополнительные услуги</h2>
                                <p class="pdf-description__text">{{order?.moreServices}}</p>
                            </div>
                            <div class="pdf-col">
                                <h2 class="pdf-description__title" v-if="order?.comment">Комментарии к заказу</h2>
                                <p class="pdf-description__text">{{order?.comment}}</p>
                            </div>
                            <div class="pdf-col">
                                <h2 class="pdf-payment__title">Подпись заказчика</h2>
                                <img class="pdf-payment__image" :src="order?.signImage" alt="" width="280" height="300">
                            </div>
                        </section>
                    </main>
                    <footer v-if="order.type === 'Благоустройство могил'" class="pdf-footer">
                        <p class="pdf-footer__text">В случае не расчёта за произведённую работу исполнитель имеет право на демонтаж данного сооружения.</p>
                        <p class="pdf-footer__text">Гарантия на установку (бетонные работы) — 12 месяцев.</p>
                        <p class="pdf-footer__text">Заказчику запрещается проверять на прочность установленный исполнителем памятник до полной сдачи работы. В противном случае гарантия аннулируется.</p>
                    </footer>
                    <footer v-if="order.type === 'Памятник'" class="pdf-footer">
                        <p class="pdf-footer__text__normal">С эскизом согласен(на), материал осмотрен, претензий не имею ____________________ подпись ____________________</p>
                        <p class="pdf-footer__text__normal">Аванс принял(а) ____________________ подпись ____________________</p>
                        <p class="pdf-footer__text__normal">Заказ принял(а) ____________________ подпись ____________________</p>
                        <p class="pdf-footer__text__normal">Памятник принят. Претензий не имею. ____________________ дата ____________________ подпись ____________________</p>
                    </footer>
                </div>
            </div>
            <div v-if="order.uploadFiles" ref="pdfFiles" class="pdf-files">
                <img v-for="file, key in order.uploadFiles" :key="key" :src="getFilePath(file)" :data-alias="key" class="pdf_break-img">
            </div>
        </div>
    </div>
 </template>
<script setup lang="ts">
// @ts-ignore
import html2pdf from 'html2pdf.js'
import { computed, onMounted, PropType, ref } from 'vue'
import logo from '../assets/img/svg/logo.svg'
import sign from '../assets/img/Vector 1.png'
import { Order, OrderService, OrderFiles } from '../app/lib/types/orders'
import ButtonComponent from '../shared/buttonComponent.vue'
import { useLoadingStore } from '../app/store/store'
import toast from '../app/lib/hooks/errorToast'

const props = defineProps({
    order: {
        type: Object as PropType<Order>,
        required: true,
    },
})

const image = {
    logo,
    sign
}

const servicesTypes = new Set(props.order.services.map((item) => item.type))

function getCurrentServiceType(type: string | undefined) {
    return props.order.services?.filter((item) => item.type === type)
}

function getCurrentTypePrice(type: string | undefined) {
    if (type) {
        let price = 0
        getCurrentServiceType(type)?.forEach((item) => {
            price += item.price
        })
        return new Intl.NumberFormat('ru-RU').format(price)
    } else {
        return 0
    }
}

function getCurrentServiceProperties(service: OrderService) {
    let properties = []
    const startBracket = '('
    const endBracket = ')'
    if (service.color) {
        properties.push(service.color)
    }
    if (service.monumentNumber) {
        properties.push(service.monumentNumber)
    }
    if (service.polish) {
        properties.push(service.polish)
    }
    if (service.chamfer) {
        properties.push(service.chamfer)
    }
    if (service.anotherMonument) {
        properties.push(service.anotherMonument)
    }
    if (service.sandblastingNumber) {
        properties.push('№' + service.sandblastingNumber)
    }
    if (service.sandblastingPageNumber) {
        properties.push('стр.' + service.sandblastingPageNumber)
    }
    if (service.epitaph) {
        properties.push(service.epitaph)
    }
    if (service.font) {
        properties.push(service.font)
    }
    if (service.portrait) {
        properties.push(service.portrait)
    }
    if (service.retouch) {
        properties.push(service.retouch)
    }
    if (service.artworkNumber) {
        properties.push('№' + service.artworkNumber)
    }
    if (service.artworkPageNumber) {
        properties.push('стр.' + service.artworkPageNumber)
    }
    if (service.anotherArtwork) {
        properties.push(service.anotherArtwork)
    }
    if (service.anotherArtworkRetouch) {
        properties.push(service.anotherArtworkRetouch)
    }
    if (service.monumentModel) {
        properties.push(service.monumentModel)
    }
    if (service.porcelainModel) {
        properties.push(service.porcelainModel)
    }
    if (service.metalPhoto) {
        properties.push(service.metalPhoto)
    }
    if (service.fastener) {
        properties.push('Крепеж: ' + service.fastener)
    }
    if (service.enamelModel) {
        properties.push('Макет эмали: ' + service.enamelModel)
    }
    if (properties.length > 0) {
        return startBracket.concat(properties.join(', '), endBracket)
    } else {
        return
    }
}

const prepay =  props.order?.payments.reduce((sum, item) => {return sum + item.amount}, 0)

const remains: number | undefined = props.order?.price.final - prepay

function getFilePath(file: OrderFiles) {
    return import.meta.env.VITE_BACKEND + file.path
}

const opt = {
    margin: 30,
    filename: `${props.order?.information.client} + `,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true, width: 1920, windowWidth: 1920, useCORS: true },
    jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ["px_scaling"] },
    pagebreak: { mode: ['css'] }
}

const pdfOrder = ref(null)
const pdfFiles = ref(null)
const loading = useLoadingStore()

async function exportPDF() {
    loading.loading()
    //@ts-ignore
    await html2pdf().set(opt).from(pdfOrder.value).toPdf().get('pdf').then((doc) => {
        if (pdfFiles.value) {
            //@ts-ignore
            for (const node of pdfFiles.value.children) {
                const orientation = node.width < node.height ? 'portrait' : 'landscape'
                doc.addPage([node.width, node.height], orientation)
                doc.addImage(node, node.currentSrc.split('.').pop(), 0, 0, node.width, node.height, node.dataset.alias, 'NONE', 0)
            }
        }
    }).save()
    loading.load()
    toast('success', 'Документ сформирован')
}

</script>
<style lang="sass">
    .pdf-box
        display: none
    .pdf-break
        break-after: always
        height: 0
        display: block
        pointer-events: none
    .pdf-button
        color: var(--white)
        background-color: var(--gold)
        padding: 10px 20px
        border-radius: 3px
        font-family: 'Roboto'
        font-weight: 400
        font-size: 14px
        display: flex
        justify-content: center
        align-items: center
        gap: 10px
        transition: .3s ease-in-out
        transition-property: background-color
        &:hover
            @media (min-width: 540px)
                background-color: var(--gray-dark)
    .order-pdf
        width: 1920px
        padding: 10px
        background-color: var(--white)
        .monument__one-page
            .pdf-header__logo-container__logo
                width: 200px
                height: 52px
            .pdf-header__text
                font-size: 24px
                & b
                    margin-right: 20px
            .pdf-hero__date
                font-size: 24px
                & b
                    margin-right: 20px
            .pdf-main
                padding-block: 30px 60px
            .pdf-hero__title span
                display: initial
            .pdf-information__one-page
                display: grid
                grid-template-columns: repeat(3, 1fr)
                gap: 60px
                margin-top: 60px
                .pdf-information__item
                    display: block
                    .pdf-information__title
                        max-width: 100%
                        margin: 0 0 10px
                        font-size: 24px
                    .pdf-information__text
                        font-size: 24px
                .pdf-information__item:not(:first-child)
                    margin-top: 30px
                .pdf-col.cemetery
                    display: grid
                    grid-template-columns: repeat(3, 1fr)
                    grid-template-rows: repeat(2, min-content)
                    gap: 30px
                    & > *:first-child
                        grid-column: 1 / 4
                    & > *
                        margin-top: 0
                .pdf-col.deceased
                    display: grid
                    grid-template-columns: repeat(2, 1fr)
                    grid-template-rows: repeat(2, min-content)
                    gap: 30px
                    & > *:first-child
                        grid-column: 1 / 3
                    & > *
                        margin-top: 0
            .pdf-service__one-page
                margin-top: 60px
                table
                    width: 100%
                    border-collapse: collapse
                    border-block: 1px solid var(--gray)
                    font-family: Roboto
                    font-size: 24px
                    font-style: normal
                    line-height: normal
                    thead
                        background-color: var(--gray-dark)
                        color: var(--white)
                        text-align: center
                        font-weight: 500
                        th:first-child
                            text-align: left
                    tbody
                        text-align: center
                        font-weight: 400
                        td:first-child
                            text-align: left
                        td:nth-child(2)
                            text-align: left
                        .table-header
                            background-color: var(--gray-light)
                            font-weight: 600
                            border-block: 1px solid var(--gray)
                            td:nth-child(2)
                                text-align: center
                    tr > th, tr > td
                        padding: 20px 10px
                        &:first-child
                            padding-inline-start: 80px
                        &:last-child
                            padding-inline-end: 80px
                        & span
                            color: #AAA
            .pdf-bottom__one-page
                margin-top: 60px
                display: grid
                grid-template-columns: 500px 500px auto
                grid-template-rows: repeat(2, min-content)
                gap: 50px
                & :first-child
                    grid-column: 1 / 2
                    grid-row: 1 / 3
                .pdf-payment__item
                    align-items: flex-start
                    .pdf-payment__title
                        font-size: 24px
                        max-width: 240px
                    .pdf-payment__number
                        font-size: 32px
                    .pdf-payment__number_small
                        font-size: 24px
                    .pdf-payment__number_gold
                        font-size: 32px
                    &:not(:first-child)
                        margin-top: 30px
                .pdf-payment__title_min
                    margin-right: 20px
            .pdf-footer
                margin-top: 0
    .pdf-header
        width: 100%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        &__logo-container
            display: flex
            &__logo
                width: auto
                display: block
        &__text-container
            text-align: right
        &__text
            display: block
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 32px
            margin-left: auto
            margin-bottom: 8px
            b
                font-weight: 500
                margin-right: 40px
            &:last-child
                margin-bottom: 0
    .pdf-main
        padding: 60px 0 100px
    .pdf-col
        width: 100%
    .pdf-hero
        width: 100%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        &__title
            color: var(--gray-dark)
            display: block
            font-family: 'Roboto'
            font-weight: 500
            font-size: 40px
            line-height: 150%
            & span
                display: block
                color: var(--gold)
        &__date
            display: block
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 32px
            text-align: right
            &:not(:first-child)
                margin-top: 20px
            b
                font-weight: 500
                margin-right: 40px
    .pdf-information
        width: 100%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        margin-top: 40px
        break-inside: avoid
        &__item
            display: flex
            &:not(:first-child)
                margin-top: 40px
        &_right
            margin-left: auto
        &__title
            max-width: 320px
            width: 100%
            display: block
            font-family: 'Roboto'
            font-weight: 500
            font-size: 28px
            margin-right: 42px
            color: var(--gray-dark)
        &__text
            display: block
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 28px
    .pdf-service
        width: 100%
        overflow: auto
        margin-top: 80px
        break-inside: avoid
        &.monument
            margin-top: 60px
    .pdf-table
        width: 100%
        border-collapse: collapse
        border: 1px solid var(--gray)
        break-inside: avoid-page
        &__title
            padding: 24px 32px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 28px
            border: 1px solid var(--gray)
            text-align: center
            &_7
                width: 7%
            &_33
                width: 33%
            &_15
                width: 15%
            &:nth-child(2)
                text-align: left
        &__text
            padding: 24px 32px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 28px
            border: 1px solid var(--gray)
            text-align: center
            &:nth-child(2)
                text-align: left
    // .pdf-monument
    //     width: 100%
    //     border-collapse: collapse
    //     border: 1px solid var(--gray)
    //     line-height: normal !important
    //     &:not(:nth-child(1))
    //         margin-top: 60px
    //     & th
    //         padding: 24px 32px
    //         color: var(--white)
    //         font-family: 'Roboto'
    //         font-weight: 500
    //         font-size: 28px
    //         border: 1px solid var(--gray)
    //         background-color: var(--gold)
    //         text-align: left
    //     & tbody tr td
    //         padding: 24px 32px
    //         color: var(--gray-dark)
    //         font-family: 'Roboto'
    //         font-weight: 400
    //         font-size: 28px
    //         border: 1px solid var(--gray)
    //         text-align: center
    //         &:nth-child(2)
    //             text-align: left
    //         & p
    //             display: block
    //             color: #69645E
    //             font-size: 24px
    //             opacity: 0.7
    //             margin-top: 8px
    //     & tbody tr:first-child td
    //         font-weight: 500
    //         background-color: var(--gray-light)
    //     & tbody tr:last-child td
    //         font-weight: 600
    //         background-color: var(--gray-light)
    //         &:first-child
    //             text-align: left
    //         &:last-child
    //             text-align: center
    .pdf-description
        width: 100%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 80px
        margin-top: 100px
        break-inside: avoid
        &__title
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            display: block
        &__text
            font-family: 'Roboto'
            font-weight: 400
            font-size: 28px
            color: var(--gray-dark)
            margin-top: 32px
    .pdf-payment
        width: 100%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 80px
        margin-top: 80px
        break-inside: always
        &__item
            display: flex
            align-items: flex-end
            &:not(:first-child)
                margin-top: 40px
        &__title
            width: 100%
            display: block
            font-family: 'Roboto'
            font-weight: 500
            font-size: 28px
            color: var(--gray-dark)
            &_min
                margin-right: 32px
                max-width: 400px
        &__text
            display: block
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 28px
        &__number
            display: block
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 40px
            &_gold
                color: var(--gold)
            &_small
                font-size: 28px
        &__image
            margin-top: 10px
    .pdf-footer
        margin-top: 10px
        width: 100%
        &__text
            font-family: 'Roboto'
            font-weight: 400
            font-size: 22px
            color: var(--gray-dark)
            &:not(:first-child)
                margin-top: 20px
            &__normal
                font-size: 28px
                &:not(:first-child)
                    margin-top: 40px
</style>