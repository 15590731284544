import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import page from "../../processes/Page.vue"
import Authorization from "../../processes/Authorization.vue"
import login from "../../components/loginComponent.vue"
import restore from "../../components/restorePasswordComponent.vue"
import newPassword from "../../components/newPasswordComponent.vue"
import createOrder from "../../components/orderCreate.vue"
import main from "../../components/mainComponent.vue"
import ordersChoice from "../../components/ordersListChoice.vue"
import ordersGraves from "../../components/ordersGravesComponent.vue"
import ordersMonument from "../../components/ordersMonumentComponent.vue"
import order from "../../components/orderComponent.vue"
import ordersArchive from "../../components/ordersArchiveComponent.vue"
import users from "../../components/usersComponent.vue"
import settings from "../../components/settingsComponent.vue"
import profile from "../../components/profileComponent.vue"
import getCookie from "../lib/utils/UseCookie"
import notFound from "../../components/404Component.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        alias: '/main',
        component: page,
        children: [
            {
                path: '/',
                alias: '/main',
                name: 'main',
                component: main,
            },
            {
                path: '/order/:orderId',
                name: 'order',
                component: order
            },
            {
                path: '/orders',
                name: 'orders',
                children: [
                    {
                        path: 'list',
                        name: 'ordersList',
                        component: ordersChoice
                    },
                    {
                        path: 'list/graveslandscaping',
                        name: 'ordersListGraves',
                        component: ordersGraves
                    },
                    {
                        path: 'list/monument',
                        name: 'ordersListMonument',
                        component: ordersMonument
                    },
                    {
                        path: 'create',
                        name: 'createOrder',
                        component: createOrder
                    },
                    {
                        path: 'archive',
                        name: 'ordersArchive',
                        component: ordersArchive,
                    },
                ]
            },
            {
                path: '/users',
                name: 'users',
                component: users,
            },
            {
                path: '/settings',
                name: 'settings',
                component: settings,
            },
            {
                path: '/profile',
                name: 'profile',
                component: profile,
            },
        ]
    },
    {
        path: '/auth',
        component: Authorization,
        children: [
            {
                path: 'login',
                name: 'login',
                component: login,
            },
            {
                path: 'restore',
                name: 'restore',
                component: restore,
            },
            {
                path: 'newPassword',
                name: 'newPassword',
                component: newPassword,
            }
        ]
    },
    {
        path: '/:catchAll(.*)',
        component: notFound
    }
]

const router = createRouter({
    routes,
    history: createWebHistory(),
})

router.beforeEach((to, from, next) => {
    const accessToken = getCookie('access_token')
    if(!accessToken) {
        if(to.name === 'login' || to.name === 'restore' || to.name === 'newPassword' || to.name === 'createOrder') {
            return next()
        } else {
            return next({
                name: 'login'
            })
        }
    }
    if(accessToken) {
        if(to.name === 'login' || to.name === 'restore' || to.name === 'newPassword') {
            return next({
                name: 'main'
            })
        } else {
            return next()
        }
    }


    next();
})

export default router