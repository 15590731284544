<template >
    <div page-card>
        <h2 v-if="orderInfomation.type === 'Благоустройство могил'" class="order-information__title">Наряд-заказ на благоустройство могилы <b>{{ orderInfomation.number }}</b></h2>
        <h2 v-if="orderInfomation.type === 'Памятник'" class="order-information__title">Наряд-заказ на гранитное изделие <b>{{ orderInfomation.number }} {{ orderInfomation.letter }}</b></h2>
        <span class="order-information__master">Создатель заказа: {{ orderInfomation.creatorContacts?.fullname }}</span>
        <span class="order-information__required">* — поля, обязательные для заполнения</span>
        <div class="order-information__form">
            <div class="order-information__item information">
                <h3 class="order-information__title_min">Даты заказа</h3>
                <div class="information__list">
                    <div class="information__item">
                        <span class="information__title">Дата заключения договора</span>
                        <span class="information__text">{{orderInfomation.createdAt ? new Date(orderInfomation.createdAt).toLocaleDateString() : 'не указано'}}</span>
                    </div>
                    <div class="information__item">
                        <span class="information__title">Дата закрытия договора</span>
                        <span class="information__text">{{orderInfomation.closedAt ? new Date(orderInfomation.closedAt).toLocaleDateString() : 'не указано'}}</span>
                    </div>
                </div>
            </div>
            <div class="order-information__item information">
                <h3 class="order-information__title_min">Контактная информация</h3>
                <div class="information__list">
                    <div class="information__item" v-if="orderInfomation.information.client">
                        <span class="information__title">Ф.И.О. заказчика</span>
                        <span class="information__text">{{orderInfomation.information.client}}</span>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.clientPhone">
                        <span class="information__title">Номер телефона</span>
                        <a :href="`tel:${orderInfomation.information.clientPhone}`" class="information__text">{{orderInfomation.information.clientPhone}}</a>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.clientAddress">
                        <span class="information__title">Адрес</span>
                        <span class="information__text">{{orderInfomation.information.clientAddress}}</span>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.clientEmail">
                        <span class="information__title">Email</span>
                        <a :href="`mailto:${orderInfomation.information.clientEmail}`" class="information__text">{{orderInfomation.information.clientEmail}}</a>
                    </div>
                </div>
            </div>
            <div class="order-information__item information">
                <h3 class="order-information__title_min">Информация о покойном</h3>
                <div class="information__list">
                    <div class="information__item" v-if="orderInfomation.information.deceased">
                        <span class="information__title">Ф.И.О. покойного</span>
                        <span class="information__text">{{orderInfomation.information.deceased}}</span>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.cemetery">
                        <span class="information__title">Дата рождения</span>
                        <span class="information__text">{{orderInfomation.information.birthDate ? new Date(orderInfomation.information.birthDate).toLocaleDateString() : 'не указано'}}</span>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.cemetery">
                        <span class="information__title">Дата смерти</span>
                        <span class="information__text">{{orderInfomation.information.deathDate ? new Date(orderInfomation.information.deathDate).toLocaleDateString() : 'не указано'}}</span>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.cemetery">
                        <span class="information__title">Место установки</span>
                        <span class="information__text">{{orderInfomation.information.cemetery}}</span>
                    </div>
                    <div class="information__list_sub">
                        <div class="information__item" v-if="orderInfomation.information.graveDistrict">
                            <span class="information__title">Участок</span>
                            <span class="information__text">{{orderInfomation.information.graveDistrict}}</span>
                        </div>
                        <div class="information__item" v-if="orderInfomation.information.graveRow">
                            <span class="information__title">Ряд</span>
                            <span class="information__text">{{orderInfomation.information.graveRow}}</span>
                        </div>
                        <div class="information__item" v-if="orderInfomation.information.gravePlace">
                            <span class="information__title">Место</span>
                            <span class="information__text">{{orderInfomation.information.gravePlace}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="orderInfomation.type === 'Благоустройство могил'" class="order-information__item service">
                <h3 class="order-information__title_min">Список оказываемых услуг</h3>
                <div class="service__list">
                    <div class="service__item" v-for="service, index in orderInfomation.services" :key="service.title">
                        <span class="service__index">{{index + 1}}</span>
                        <div class="information__item">
                            <span class="service__title">Наименование услуги</span>
                            <span class="service__text">{{service.title}}</span>
                        </div>
                        <div class="service__parametrs">
                            <div class="information__item">
                                <span class="information__title">Размер</span>
                                <span class="information__text">{{service.quantity}}</span>
                            </div>
                            <div class="information__item">
                                <span class="information__title">Ед. изм.</span>
                                <span class="information__text">{{service.measurement}}</span>
                            </div>
                            <div class="information__item">
                                <span class="information__title">Цена за ед., руб.</span>
                                <span class="information__text">{{service.cost}}</span>
                            </div>
                            <div class="information__item">
                                <span class="information__title">Сумма, руб.</span>
                                <span class="information__text">{{service.price}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="orderInfomation.type === 'Памятник'" class="order-information__item service">
                <h3 class="order-information__title_min">Список оказываемых услуг</h3>
                <div class="service__list">
                    <div class="service__item" v-for="service, index in orderInfomation.services" :key="service.title">
                        <div v-if="service.title === 'Памятник'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Цвет</span>
                                    <span class="information__text">{{service.color}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Номер памятника в каталоге</span>
                                    <span class="information__text">{{service.monumentNumber}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Размер</span>
                                    <span class="information__text">{{service.size}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Полировка</span>
                                    <span class="information__text">{{service.polish}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Подставка' || service.title === 'Цветник' || service.title === 'Надгробная плита'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Цвет</span>
                                    <span class="information__text">{{service.color}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Размер</span>
                                    <span class="information__text">{{service.size}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Полировка</span>
                                    <span class="information__text">{{service.polish}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Ваза' || service.title === 'Подсвечник' || service.title === 'Фото ниша под керамогранит'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Размер</span>
                                    <span class="information__text">{{service.size}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Техническое отверстие'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Фаска'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Тип фаски</span>
                                    <span class="information__text">{{service.chamfer}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Полировка</span>
                                    <span class="information__text">{{service.polish}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Иное гранитное изделие'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Название изделия</span>
                                    <span class="information__text">{{service.anotherMonument}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Размер</span>
                                    <span class="information__text">{{service.size}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Полировка</span>
                                    <span class="information__text">{{service.polish}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Шрифт' || service.title === 'Пескоструйные изображения'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Номер в пескоструйном каталоге</span>
                                    <span class="information__text">{{service.sandblastingNumber}}</span>
                                </div>
                                <div v-if="service.title === 'Пескоструйные изображения'" class="information__item">
                                    <span class="information__title">Страница в пескоструйном каталоге</span>
                                    <span class="information__text">{{service.sandblastingPageNumber}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Крест' && service.type === 'Пескоструйные работы'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Номер в пескоструйном каталоге</span>
                                    <span class="information__text">{{service.sandblastingNumber}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Покраска'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Цвет</span>
                                    <span class="information__text">{{service.color}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Эпитафия'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Номер в каталоге / Иное</span>
                                    <span class="information__text">{{service.epitaph}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Шрифт</span>
                                    <span class="information__text">{{service.font}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Портрет'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Тип портрета</span>
                                    <span class="information__text">{{service.portrait}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Ретушь'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Тип ретуши</span>
                                    <span class="information__text">{{service.retouch}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Цветы' || service.title === 'Свечи' || service.title === 'Пейзаж'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Номер в художественном каталоге</span>
                                    <span class="information__text">{{service.artworkNumber}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Страница в художественном каталоге</span>
                                    <span class="information__text">{{service.artworkPageNumber}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Крест' && service.type === 'Художественные работы'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Номер в художественном каталоге</span>
                                    <span class="information__text">{{service.artworkNumber}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Страница в художественном каталоге</span>
                                    <span class="information__text">{{service.artworkPageNumber}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Иное изображение'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Название</span>
                                    <span class="information__text">{{service.anotherArtwork}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Ретушь</span>
                                    <span class="information__text">{{service.anotherArtworkRetouch}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Макет памятника'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Тип макета памятника</span>
                                    <span class="information__text">{{service.monumentModel}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.title === 'Керамогранит' || service.title === 'Металлофото' || service.title === 'Табличка эмаль \\ фото эмаль'">
                            <span class="service__index">{{index + 1}}</span>
                            <div class="information__item">
                                <span class="service__title">Наименование услуги</span>
                                <span class="service__text">{{service.title}}</span>
                            </div>
                            <div class="service__parametrs">
                                <div class="information__item">
                                    <span class="information__title">Размер</span>
                                    <span class="information__text">{{service.size}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Количество</span>
                                    <span class="information__text">{{service.quantity}}</span>
                                </div>
                                <div v-if="service.title === 'Табличка эмаль \\ фото эмаль'" class="information__item">
                                    <span class="information__title">Крепеж</span>
                                    <span class="information__text">{{service.fastener}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена</span>
                                    <span class="information__text">{{service.price}}</span>
                                </div>
                                <div v-if="service.title === 'Керамогранит'" class="information__item">
                                    <span class="information__title">Макет керамогранита</span>
                                    <span class="information__text">{{service.porcelainModel}}</span>
                                </div>
                                <div v-if="service.title === 'Металлофото'" class="information__item">
                                    <span class="information__title">Макет металлофото</span>
                                    <span class="information__text">{{service.metalPhoto}}</span>
                                </div>
                                <div v-if="service.title === 'Табличка эмаль \\ фото эмаль'" class="information__item">
                                    <span class="information__title">Макет эмали</span>
                                    <span class="information__text">{{service.enamelModel}}</span>
                                </div>
                                <div class="information__item">
                                    <span class="information__title">Цена за макет</span>
                                    <span class="information__text">{{service.modelPrice}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-information__item additional-service" v-if="orderInfomation.moreServices">
                <h3 class="order-information__title_min">Дополнительные услуги</h3>
                <p class="additional-service__field info">{{orderInfomation.moreServices}}</p>
            </div>
            <div class="order-information__item payment">
                <div class="col">
                    <div class="cost">
                        <h3 class="order-information__title_min">Расчёт стоимости</h3>
                        <div class="cost__list">
                            <div class="col">
                                <div class="payment__value-box">
                                    <span class="payment__label">Сумма заказа</span>
                                    <div class="payment__value payment__value_min">{{new Intl.NumberFormat('ru-RU').format(orderInfomation.price.total)}} руб.</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="payment__value-box">
                                    <span class="payment__label">Тип оплаты</span>
                                    <div class="payment__value payment__value_min min">{{prepay === 0 ? 'без предоплаты' : remains === 0 ? 'полный расчёт' : 'авансовый платёж'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="discount">
                        <div class="col">
                            <div class="payment__value-box">
                                <span class="payment__label">Скидка</span>
                                <div class="payment__value payment__value_min min">{{orderInfomation.price.discountValue}} {{orderInfomation.price.discountMeasure}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="payment__value-box">
                                <span class="payment__label">Размер аванса</span>
                                <div class="payment__value payment__value_min min">{{new Intl.NumberFormat('ru-RU').format(prepay)}} руб.</div>
                            </div>
                        </div>
                    </div>
                    <div class="create-order__item pay">
                        <div class="col">
                            <div class="payment__value-box">
                                <span class="payment__label payment__label_big">К оплате</span>
                                <div class="payment__value payment__value_gold">{{new Intl.NumberFormat('ru-RU').format(orderInfomation.price.final)}} руб.</div>
                            </div>
                            <div class="payment__value-box payment__value_mt">
                                <span v-if="orderInfomation.payments[0]" class="payment__label">Способ оплаты</span>
                                <div class="payment__value payment__value_min min">{{orderInfomation.payments[0]?.method}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="payment__value-box">
                                <span class="payment__label">Остаток</span>
                                <div class="payment__value">{{new Intl.NumberFormat('ru-RU').format(orderInfomation.price.final - prepay)}} руб.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <h3 class="order-information__title_min">Сроки выполнения работ</h3>
                    <div class="order-information__date">
                        <div class="col" v-if="orderInfomation.dates.startAt">
                            <div class="payment__value-box">
                                <span class="payment__label">Начало работ</span>
                                <div class="payment__value min">{{new Date(orderInfomation.dates.startAt).toLocaleDateString()}}</div>
                            </div>
                        </div>
                        <div class="col" v-if="orderInfomation.dates.endAt">
                            <div class="payment__value-box">
                                <span class="payment__label">Окончание работ</span>
                                <div class="payment__value min">{{new Date(orderInfomation.dates.endAt).toLocaleDateString()}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="order-information__item order-information__comment" v-if="orderInfomation.comment">
                        <div class="order-information__item additional-service">
                            <h3 class="order-information__title_min">Комментарий к заказу</h3>
                            <p class="additional-service__field additional-service__field_text info">{{orderInfomation.comment}}</p>
                        </div>
                    </div>
                    <div class="order-information__item order-information__upload">
                        <div class="order-information__item additional-service uploaded-files">
                            <h3 class="order-information__title_min">Прикреплённые файлы</h3>
                            <button-component v-for="file, key in orderInfomation.uploadFiles" :key="key" class="button image uploaded" :button-text=file.name @click="openImage = true; openPopup(key)"/>
                        </div>
                    </div>
                    <div class="order-information__item order-information__signature" v-if="orderInfomation.signImage">
                        <h3 class="order-information__title_min">Подпись заказчика</h3>
                        <img :src="orderInfomation.signImage" alt="" width="280" height="300">
                    </div>
                </div>
            </div>
        </div>
        <popup-component :mt="true" :isOpen="openImage === true" @close="openImage = false">
            <template #title>
                Фото
            </template>
            <template #content>
                <img class="upload-image" v-if="filePaths" :src="filePath" alt="image">
            </template>
        </popup-component>
    </div>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';
import { useRoute } from 'vue-router';
import { Order } from '../app/lib/types/orders';
import ButtonComponent from '../shared/buttonComponent.vue';
import PopupComponent from '../entities/popupComponent.vue';

const props = defineProps({
    order: {
        type: Object as PropType<Order>,
        required: true
    }
})

const prepay =  props.order?.payments.reduce((sum, item) => {return sum + item.amount}, 0)

const remains: number | undefined = props.order?.price.final - prepay

const router = useRoute()
let orderInfomation: Order = props.order
const openImage = ref(false)
const filePaths = orderInfomation.uploadFiles?.map((file) => import.meta.env.VITE_BACKEND + file.path)
let filePath = ''

const openPopup = (key: number) => {
    filePaths !== undefined ? filePath = filePaths[key] : filePath = ''
}
</script>

<style lang="sass">
    .order-information
        &__title
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            color: var(--brown)
            &_min
                font-family: 'Roboto'
                font-weight: 500
                font-size: 24px
                color: var(--brown)
            b
                color: var(--gold)
        &__master
            display: block
            font-family: 'Roboto'
            font-weight: 400
            color: var(--black)
            margin-top: 10px
        &__required
            display: block
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--red)
            margin-top: 10px
        &__item
            margin-top: 60px
        &__button
            display: flex
            gap: 5px
            padding: 12px 28px
            border: 2px solid var(--gold)
            color: var(--gold)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 14px
            margin-top: 30px
        &__submit
            background: var(--gold)
            padding: 14px 30px
            max-width: 386px
            width: 100%
            margin-top: 60px
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--white)
        &__remove
            margin-top: 60px
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--gray)
            text-decoration: underline
        &__date
            display: grid
            grid-template-columns: repeat(2, calc( 50% - 30px ))
            gap: 30px
            margin-top: 30px
            &-item
                margin-top: 14px
            @media (max-width: 450px)
                grid-template-columns: 100%
    .information
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(3, 4fr)
            gap: 30px
            @media (max-width: 1200px)
                grid-template-columns: repeat(2, 6fr)
            @media (max-width: 1200px)
                grid-template-columns: 100%
            &_sub
                display: grid
                grid-template-columns: repeat(3, 120px)
                gap: 13px
                @media (max-width: 430px)
                    grid-template-columns: 100%
        &__item
            width: 100%
            display: block
        &__title
            display: block
            color: var(--brown)
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
        &__text
            display: block
            margin-top: 10px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
    .service
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(3, 4fr)
            gap: 30px
            @media (max-width: 1450px)
                grid-template-columns: repeat(2, 4fr)
                gap: 30px
            @media (max-width: 990px)
                grid-template-columns: 100%
                gap: 30px
        &__item
            width: 100%
            padding: 20px
            border: 2px solid var(--gold)
            .vs__dropdown-toggle
                width: 100%
                height: 48px
                border: none
                background-color: var(--gray-light)
                margin-top: 10px
                padding: 14px
                align-items: center
            .vs__search
                &::placeholder
                    color: var(--gray)
            .vs__actions
                flex: none
            .vs__clear
                display: none
        &__delete
            padding: 5px 10px
            width: 100%
            border: 1px solid var(--gray)
            border-radius: 3px
            background: none
            display: flex
            justify-content: center
            align-items: center
            gap: 10px
            font-family: 'Roboto'
            font-weight: 500
            font-size: 14px
            color: var(--gray)
        &__title
            display: block
            margin-top: 30px
            color: var(--brown)
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
        &__text
            display: block
            font-weight: 400
            font-size: 18px
            margin-top: 20px
            color: var(--gray-dark)
        &__index
            display: block
            width: 100%
            margin-top: 10px
            padding: 5px
            text-align: center
            background-color: var(--brown)
            color: var(--white)
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            border-radius: 3px
        &__checklist
            margin-top: 30px
        &__parametrs
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(2, calc( 50% - 15px ))
            gap: 30px
            @media (max-width: 400px)
                grid-template-columns: 100%
    .additional-service
        &__field
            width: 100%
            margin-top: 20px
            display: block
            border-radius: 3px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 16px
            &_text
                background: none
            &.info
                background: none
                padding: 0
                font-size: 16px
                height: auto
    .payment
        display: grid
        grid-template-columns: repeat(2, 6fr)
        gap: 30px
        @media (max-width: 1230px)
            display: flex
            flex-direction: column-reverse
            width: 100%
        &__label
            display: block
            font-family: 'Roboto'
            font-weight: 300
            font-size: 16px
            color: var(--brown)
            b
                color: var(--red)
            &_big
                font-family: 'Roboto'
                font-weight: 500
                font-size: 20px
                color: var(--brown)
        &__value
            margin-top: 15px
            font-family: 'Roboto'
            font-weight: 400
            font-size: 16px
            color: var(--brown)
            &_min
                font-size: 20px
            &_gold
                font-size: 28px
                color: var(--gold)
            &_flex
                display: flex
                gap: 16px
                align-items: start
            &_mt
                margin-top: 30px
            &.min
                font-size: 16px
                font-weight: 400
    .cost
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(2, 200px)
            gap: 30px
            @media (max-width: 450px)
                grid-template-columns: 100%
    .radio
        &__list
            margin-top: 14px
            flex: none
        &__item
            display: flex
            align-items: center
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--gray-dark)
            input
                width: 20px
                height: 20px
                margin-right: 8px
            &:not(:first-child)
                margin-top: 14px
    .discount
        margin-top: 30px
        display: grid
        grid-template-columns: repeat(2, 200px)
        gap: 30px
        @media (max-width: 450px)
                grid-template-columns: 100%
    .pay
        margin-top: 30px
        display: grid
        grid-template-columns: repeat(2, 200px)
        gap: 30px
        @media (max-width: 450px)
                grid-template-columns: 100%
    .v-popper__arrow-container
        display: none
    .uploaded
        color: var(--gold)
        font-family: 'Roboto'
        font-size: 14px
        font-style: normal
        font-weight: 400
        line-height: normal
        text-decoration: underline
        margin-top: 30px
    .signature
        &-box
            border: 1px solid var(--gray)
            border-radius: 5px
            overflow: hidden
            position: relative
        &__undo
            height: 20px
            &-box
                position: absolute
                top: 5px
                right:5px
                z-index: 2
                border: none
                background: none
        &__button
            text-transform: uppercase
            color: var(--white)
            background: var(--gold)
            width: 100%
            padding: 13px 10px
            &_gray
                background: none
                color: var(--gray)
                border: 1px solid var(--gray)
                margin-top: 20px
            &-box
                width: 100%
                margin-top: 20px
    .upload-image
        width: 100%
        height: 100%
        object-fit: cover
        display: block
</style>