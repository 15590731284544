<template>
    <field-template :title="props.inputLabel" :required="required">
        <template #content>
            <div class="input__wrapper">
                <p class="input input__text" v-if="input !== true">{{meaning}}</p>
                <div class="input-box" v-else>
                    <input
                        :value="meaning || ''"
                        @input="$emit('update:meaning',
                            //@ts-ignore
                            inputType == 'number' ? +$event.target.value : $event.target.value
                        )"
                        :type="inputType"
                        class="input"
                        :class="{input__error: inputError}"
                        :name="inputName"
                        :placeholder="inputContent"
                        :autocomplete="inputAutocomplete"
                        :disabled="disabled"
                        :min="min"
                        :max="max"
                    />
                    <span class="input__error-text" v-if="inputError">{{ inputError }}</span>
                </div>
                <slot name="subcontent">

                </slot>
            </div>
        </template>
    </field-template>
</template>
<script setup lang="ts">
import FieldTemplate from '../../Field/FieldTemplate.vue';
const props = defineProps({
    inputType: {
        type: String,
        required: true,
    },
    inputName: {
        type: String,
        required: true,
    },
    inputContent: {
        type: String,
        required: true,
    },
    inputLabel: {
        type: String,
    },
    inputError: {
        type: String,
    },
    inputAutocomplete: {
        type: String,
        default: "off",
    },
    meaning: {
        type: [String, Number],
    },
    required: {
        type: Boolean
    },
    disabled: {
        type: Boolean
    },
    min: {
        type: Number
    },
    max: {
        type: Number
    },
    input: {
        type: Boolean,
        default: true,
    }
})

</script>
<style lang="sass">
.input
    width: 100%
    padding: 14px
    border: none
    border-radius: 3px
    background-color: var(--gray-light)
    color: var(--gray-dark)
    font-family: 'Roboto'
    font-weight: 400
    font-size: 18px
    &::placeholder
        color: var(--gray)
    &__error
        border: 1px solid var(--red)
        &-text
            display: block
            color: var(--red)
            margin-top: 5px
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
    &__wrapper
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(70px, 1fr))
        width: 100%
        gap: 16px
        align-items: center
    &__text
        background: none
    &-box
        width: 100%
</style>
