<template>
    <div class="update-fields">
        <div v-if="order.type === 'Благоустройство могил'" class="update-fields__list">
            <calculation-block
                name="raw"
                content="0"
                label="ЦПА"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.rawMeasure"
                :meaning="costs.costs.rawValue"
                :price="price"
                :calculateFunction="Calculate.easy"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
            <calculation-block
                name="materials"
                content="0"
                label="Расходники"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.materialsMeasure"
                :meaning="costs.costs.materialsValue"
                :price="price"
                :calculateFunction="Calculate.easy"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
            <calculation-block
                name="concrete"
                content="0"
                label="МАП"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.concreteMeasure"
                :meaning="costs.costs.concreteValue"
                :price="price"
                :calculateFunction="Calculate.hard"
                :calculateArguments="[
                    //@ts-ignore
                    costs
                ]"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
            <calculation-block
                name="install"
                content="0"
                label="Установщики"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.installMeasure"
                :meaning="costs.costs.installValue"
                :price="price"
                :calculateFunction="Calculate.hard"
                :calculateArguments="[
                    //@ts-ignore
                    costs
                ]"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
            <calculation-block
                name="brigade"
                content="0"
                label="Бригада"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.brigadeMeasure"
                :meaning="costs.costs.brigadeValue"
                :price="price"
                :calculateFunction="Calculate.easy"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
            <calculation-block
                name="departure"
                content="0"
                label="Выезд"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.departureMeasure"
                :meaning="costs.costs.departureValue"
                :price="price"
                :calculateFunction="Calculate.easy"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
        </div>
        <div v-if="order.type === 'Памятник'" class="update-fields__list">
            <calculation-block
                name="stone"
                content="0"
                label="Камень закупка"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.stoneMeasure"
                :meaning="costs.costs.stoneValue"
                :price="price"
                :calculateFunction="Calculate.easy"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
            <calculation-block
                name="net"
                content="0"
                label="Себестоимость керамогранит, металлофото, эмаль"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.netMeasure"
                :meaning="costs.costs.netValue"
                :price="price"
                :calculateFunction="Calculate.easy"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
            <calculation-block
                name="polish"
                content="0"
                label="ЗП резка и полировка"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.polishMeasure"
                :meaning="costs.costs.polishValue"
                :price="price"
                :calculateFunction="Calculate.easy"
                :calculateArguments="[
                    //@ts-ignore
                    costs
                ]"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
            <calculation-block
                name="manager"
                content="0"
                label="ЗП менеджер"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.managerMeasure"
                :meaning="costs.costs.managerValue"
                :price="price"
                :calculateFunction="Calculate.easy"
                :calculateArguments="[
                    //@ts-ignore
                    costs
                ]"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
            <calculation-block
                name="implementer"
                content="0"
                label="ЗП исполнитель"
                :input="edit"
                :disabled="disabled"
                :radioOptions="options"
                :selectedOption="costs.costs.implementerMeasure"
                :meaning="costs.costs.implementerValue"
                :price="price"
                :calculateFunction="Calculate.easy"
                @onChange="(data: ICost) => costs.setCost(data)"
            />
        </div>
        <button-component class="update-fields__button" buttonText="Редактировать поля" v-if="edit === false" @click.prevent="setEdit" />
        <button-component class="update-fields__button" buttonText="Отменить редактирование" v-if="edit === true" @click.prevent="removeEditCosts" />
        <button-component class="update-fields__button" buttonText="Обновить поля" v-if="edit === true" @click.prevent="setCosts" />
    </div>
</template>
<script setup lang="ts">
import { PropType, onUnmounted, ref, watch } from 'vue';
import ButtonComponent from '../../../../../shared/buttonComponent.vue';
import { CalculationBlock } from '../../../../../features/CalculationBlock';
import { Order, OrderCosts, PaymentMeasure } from '../../../../../app/lib/types/orders';
import axios from '../../../../../app/lib/api';
import toast from '../../../../../app/lib/hooks/errorToast';
import { Calculate } from '../../../../../features/CalculationBlock/lib/Calculate';
import { useCostsStore, ICost } from '../../lib/store/store';
import { useLoadingStore } from '../../../../../app/store/store';
import _ from 'lodash';


const props = defineProps({
    order: {
        type: Object as PropType<Order>,
        required: true
    },
    data: {
        type: Object as PropType<OrderCosts>,
        required: true
    },
    id: {
        type: String,
        required: true
    },
    price: {
        type: Number,
        required: true
    }
})

const emit = defineEmits({
    close: null,
    costs: null
})

const options = Object.values(PaymentMeasure).map((item) => {
    return {
        label: item,
        value: item
    }
})

const costs = useCostsStore()

const loading = useLoadingStore()

const edit = ref(false)
const disabled = ref(true)

if(props.data) {
    costs.getCosts(_.cloneDeep(props.data), props.price)
}

function removeEditCosts() {
    costs.$reset()
    costs.getCosts(_.cloneDeep(props.data), props.price)
    edit.value = false
    disabled.value = true
}

function setEdit() {
    edit.value = true
    disabled.value = false
}

async function setCosts() {
    try {
        loading.loading()
        await axios.order.updateOrderCosts(props.id, costs.costs)
        loading.load()
        emit("close", {
            data: false
        })
        emit("costs", {
            data: costs.costs
        })
        toast("success", "поля успешно отправлены")
    } catch (error: any) {
        loading.load()
        emit("close", {
            data: false
        })
        toast("error", error.response.data.message)
    }
}

onUnmounted(() => {
    costs.$reset()
    console.log(costs)
})

watch(() => [costs.costs], () => {
    console.log(costs.costs)
    console.log(costs)
}, {deep: true})

</script>
<style lang="sass">
    .update-fields
        &__list
            display: grid
            grid-template-columns: repeat(2, 200px)
            gap: 30px
            @media (max-width: 630px)
                grid-template-columns: repeat(2, 150px)
        &__button
            margin: 0 auto
            margin-top: 30px
            border: 2px solid var(--gold)
            color: var(--gold)
            padding: 12px 28px
</style>
