import { Contacts, UserRegion } from "./users";

export enum OrderStatus {
    processing = "Обработка",
    active = "В работе",
    done = "Сделан",
    payment = "Оплачен",
    donePayment = "Закрыт",
    archive = "В архиве",
    refusal = "Отказ"
}

export enum OrderType {
    gravesLandscaping = "Благоустройство могил",
    monument = "Памятник"
}

export enum PaymentMeasure {
    percent = "%",
    currency = "руб",
}

export enum PrepaymentType {
    prepayment = "авансовый платёж",
    fullPrepayment = "полный расчёт",
    withoutPrepayment = "без предоплаты",
}

export enum PaymentMethod {
    card = "банковской картой",
    cash = "наличными",
}

//TODO изменить параметр signImage на ссылку на файл
export interface Order {
    _id: string;
    index: number;
    number: string;
    userId: string; // создатель заказа
    executorId: string; // исполнитель
    type: OrderType;
    region: UserRegion;
    letter?: string;
    information: OrderInformation;
    services: OrderService[];
    moreServices: string;
    costs: OrderCosts;
    price: OrderPrice;
    payments: OrderPayment[];
    dates: OrderDates;
    comment?: string;
    uploadFiles?: OrderFiles[];
    signImage: string;
    status: OrderStatus;
    createdAt: Date;
    updatedAt: Date;
    closedAt: Date;
    creatorContacts?: Contacts; // контакты создателя заказа
    executorContacts?: Contacts; // контакты исполнителя
}

interface OrderInformation {
    client: string;
    clientPhone: string;
    clientAddress?: string;
    clientEmail?: string;
    deceased: string;
    birthDate: Date;
    deathDate: Date;
    cemetery: string;
    graveDistrict: string;
    graveRow: string;
    gravePlace: string;
    manager?: string;
}

export interface OrderService {
    type?: string;
    title: string;
    measurement?: string;
    quantity: number;
    cost: number;
    color?: string;
    monumentNumber?: string;
    size?: string;
    polish?: string;
    chamfer?: string;
    anotherMonument?: string;
    sandblastingNumber?: string;
    sandblastingPageNumber?: string;
    epitaph?: string;
    font?: string;
    portrait?: string;
    retouch?: string;
    artworkNumber?: string;
    artworkPageNumber?: string;
    anotherArtwork?: string;
    anotherArtworkRetouch?: string;
    monumentModel?: string;
    porcelainModel?: string;
    metalPhoto?: string;
    fastener?: string;
    enamelModel?: string;
    modelPrice?: number;
    price: number;
}

interface OrderPrice {
    total: number;
    discountValue: number;
    discountMeasure: PaymentMeasure;
    discount: number;
    final: number;
}

export interface OrderPayment {
    amount: number;
    method: PaymentMethod;
    date: Date;
}

interface OrderDates {
    startAt: Date;
    endAt: Date;
}

export interface OrderCosts {
    concrete: number,
    concreteValue: number,
    concreteMeasure: string,
    materials: number,
    materialsValue: number,
    materialsMeasure: string,
    departure: number,
    departureValue: number,
    departureMeasure: string,
    brigade: number,
    brigadeValue: number,
    brigadeMeasure: string,
    raw: number,
    rawValue: number,
    rawMeasure: string,
    install: number,
    installValue: number,
    installMeasure: string,
    stone: number, // камень закупка
    stoneValue: number,
    stoneMeasure: string,
    net: number, // себестоимость керамогранит, металлофото, эмаль
    netValue: number,
    netMeasure: string,
    polish: number, // з/п резка и полировка
    polishValue: number,
    polishMeasure: string,
    manager: number, // з/п менеджер
    managerValue: number,
    managerMeasure: string,
    implementer: number, // з/п исполнитель (создатель заказа)
    implementerValue: number,
    implementerMeasure: string
}

export interface OrderFiles {
    name: string,
    path: string,
    delete: boolean
}

/**
 * Общая сумма ценна у всех заказов, где:
 * priceFinal - общая сумма заказов
 * costsConcrete - общая сумма МАП
 * costsMaterials - общая сумма материалов (в таблице "Себестоимость ограды+скамейка+стол+плитка+крошка")
 * costsInstall - общая сумма установки ( в таблице "Оплата установщикам")
 * debtPaymentsAmount - общая сумма платежей (учавствует в вычислении суммы долга)
 * qty - количество элементов (заказов), которое попало в выборку
 * debtAmount - сумма долга
 * revenue - сумма выручки
 */
export interface IOrderCostsAmount {
    priceFinal: number,
    costsConcrete: number,
    costsMaterials: number,
    costsInstall: number,
    debtPaymentsAmount: number,
    qty: number,
    debtAmount: number,
    revenue: number
}

/**
 * Фильтр поиска заказов.
 */
export interface IOrderFilter {
    perPage?: number, 
    sortBy?: string, 
    sortOrder?: string,
    pageOffset?: number,
    useTableMap?: boolean,
    search?: {
        searchBy?: IOrderFilterSearchBy[]
    }
}

/**
 * Поисковый запрос для фильтра поиска заказов.
 */
export interface IOrderFilterSearchBy {
    field: string,
    value: string,
    useRegEx?: boolean,
    nin?: string | string[],
    in?: string | string[]
}