<template>
    <div class="field">
        <span class="field__title" :class="{field__title_big: props.big}">{{title}} <b v-if="required">*</b></span>
        <div class="field__content">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script setup lang="ts">
    const props = defineProps({
        title: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        big: {
            type: Boolean,
        },
    })

</script>
<style lang="sass">
    .field
        width: 100%
        &__title
            font-weight: 300
            font-size: 18px
            color: var(--brown)
            &_big
                font-weight: 500
                font-size: 24px
            b
                color: var(--red)
        &__content
            margin-top: 14px
</style>