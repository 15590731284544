import {object, array, string, number, boolean, InferType, date, mixed} from "yup"

export const updateOrderShema = object({
    information: object({
        client: string().required('Введите ФИО заказчика'),
        clientPhone: string().required('Введите номер телефона'),
        clientAddress: string(),
        clientEmail: string(),
        deceased: string().required('Введите ФИО покойника'),
        birthDate: mixed(),
        deathDate: mixed(),
        cemetery: string().required('укажите место установки'),
        graveDistrict: string().required('Введите участок'),
        graveRow: string().required('Введите Ряд'),
        gravePlace: string().required('Введите место'),
        manager: string()
    }),
    services: array(
        object({
            type: string(),
            title: string().required('Выберите услугу'),
            measurement: string(),
            quantity: number().required(),
            cost: number().required(),
            color: string(),
            monumentNumber: string(),
            size: string(),
            polish: string(),
            chamfer: string(),
            anotherMonument: string(),
            sandblastingNumber: string(),
            sandblastingPageNumber: string(),
            epitaph: string(),
            font: string(),
            portrait: string(),
            retouch: string(),
            artworkNumber: string(),
            artworkPageNumber: string(),
            anotherArtwork: string(),
            anotherArtworkRetouch: string(),
            monumentModel: string(),
            porcelainModel: string(),
            metalPhoto: string(),
            fastener: string(),
            enamelModel: string(),
            modelPrice: number(),
            price: number().required(),
        })
    ),
    moreServices: string(),
    price: object({
        total: number(),
        discountValue: number(),
        discountMeasure: string(),
        discount: number(),
        final: number(),
    }),
    dates: object({
        startAt: date().required('Укажите дату начала работ'),
        endAt: date().required('Укажите дату конца работ'),
    }),
    comment: string(),
    uploadFiles: array(
        object({
            name: string().required('Не указано название файла'),
            delete: boolean()
        }).nullable()
    ),
    costs: object({
        concrete: number().required('ошибка заполнения'),
        concreteValue: number().required('ошибка заполнения'),
        concreteMeasure: string().required('ошибка заполнения'),
        materials: number().required('ошибка заполнения'),
        materialsValue: number().required('ошибка заполнения'),
        materialsMeasure: string().required('ошибка заполнения'),
        departure: number().required('ошибка заполнения'),
        departureValue: number().required('ошибка заполнения'),
        departureMeasure: string().required('ошибка заполнения'),
        brigade: number().required('ошибка заполнения'),
        brigadeValue: number().required('ошибка заполнения'),
        brigadeMeasure: string().required('ошибка заполнения'),
        raw: number().required('ошибка заполнения'),
        rawValue: number().required('ошибка заполнения'),
        rawMeasure: string().required('ошибка заполнения'),
        install: number().required('ошибка заполнения'),
        installValue: number().required('ошибка заполнения'),
        installMeasure: string().required('ошибка заполнения'),
        stone: number().required('ошибка заполнения'),
        stoneValue: number().required('ошибка заполнения'),
        stoneMeasure: string().required('ошибка заполнения'),
        net: number().required('ошибка заполнения'),
        netValue: number().required('ошибка заполнения'),
        netMeasure: string().required('ошибка заполнения'),
        polish: number().required('ошибка заполнения'),
        polishValue: number().required('ошибка заполнения'),
        polishMeasure: string().required('ошибка заполнения'),
        manager: number().required('ошибка заполнения'),
        managerValue: number().required('ошибка заполнения'),
        managerMeasure: string().required('ошибка заполнения'),
        implementer: number().required('ошибка заполнения'),
        implementerValue: number().required('ошибка заполнения'),
        implementerMeasure: string().required('ошибка заполнения'),
    })
})


export type updateOrderType = InferType<typeof updateOrderShema>


export const orderPaymentShema =  object({
    date: date(),
    amount: number().moreThan(0, 'Введите сумму'),
    method: string().required('Укажите способ оплаты'),
})

export type orderPaymentType = InferType<typeof orderPaymentShema>