import {object, array, string, number, boolean, InferType, date, mixed} from "yup"

export const createOrderShema = object({
    type: string(),
    letter: string(),
    information: object({
        client: string().required('Введите ФИО заказчика'),
        clientPhone: string().required('Введите номер телефона'),
        clientAddress: string(),
        clientEmail: string(),
        deceased: string().required('Введите ФИО покойника'),
        birthDate: string().required('Укажите дату рождения'),
        deathDate: string().required('Укажите дату смерти'),
        cemetery: string().required('Укажите место установки'),
        graveDistrict: string().required('Введите участок'),
        graveRow: string().required('Введите Ряд'),
        gravePlace: string().required('Введите место'),
        manager: string(),
    }),
    services: array(
        object({
            type: string(),
            title: string().required('Выберите услугу'),
            measurement: string(),
            quantity: number().required(),
            cost: number().required(),
            color: string(), // цвет, сюда же складываем покраску
            monumentNumber: string(), // номер памятника в каталоге
            size: string(), // размер
            polish: string(), // полировка
            chamfer: string(), // тип фаски
            anotherMonument: string(), // иное гранитное изделие
            sandblastingNumber: string(), // номер в пескоструйном каталоге
            sandblastingPageNumber: string(), // номер страницы в пескоструйном каталоге
            epitaph: string(), // эпитафия (номер в каталоге / иное)
            font: string(), // шрифт
            portrait: string(), // тип портрета
            retouch: string(), // тип ретуши
            artworkNumber: string(), // номер в художественном каталоге
            artworkPageNumber: string(), // номер страницы в художественном каталоге
            anotherArtwork: string(), // иное изображение
            anotherArtworkRetouch: string(), // цена ретуши для иного изображения
            monumentModel: string(), // тип макета памятника
            porcelainModel: string(), // макет керамогранита
            metalPhoto: string(), // макет металлофото
            fastener: string(), // крепеж
            enamelModel: string(), // макет эмали
            modelPrice: number(), // цена за макет
            price: number().required(),
        })
    ).min(1, 'services'),
    moreServices: string(),
    price: object({
        total: number().required(),
        discountValue: number().required(),
        discountMeasure: string().required(),
        discount: number().required(),
        final: number().required(),
    }),
    prepayment: object({
        date: date(),
        amount: number(),
        method: string().required('Укажите способ оплаты'),
    }).nullable(),
    dates: object({
        startAt: date().required('Укажите дату начала работ'),
        endAt: date().required('Укажите дату конца работ'),
    }),
    comment: string(),
    uploadFiles: array(
        object({
            name: string().required('Не указано название файла'),
            delete: boolean()
        }).nullable()
    ),
    signImage: string().required('Поставьте подпись'),
    costs: object({
        concrete: number().required('ошибка заполнения'),
        concreteValue: number().required('ошибка заполнения'),
        concreteMeasure: string().required('ошибка заполнения'),
        materials: number().required('ошибка заполнения'),
        materialsValue: number().required('ошибка заполнения'),
        materialsMeasure: string().required('ошибка заполнения'),
        departure: number().required('ошибка заполнения'),
        departureValue: number().required('ошибка заполнения'),
        departureMeasure: string().required('ошибка заполнения'),
        brigade: number().required('ошибка заполнения'),
        brigadeValue: number().required('ошибка заполнения'),
        brigadeMeasure: string().required('ошибка заполнения'),
        raw: number().required('ошибка заполнения'),
        rawValue: number().required('ошибка заполнения'),
        rawMeasure: string().required('ошибка заполнения'),
        install: number().required('ошибка заполнения'),
        installValue: number().required('ошибка заполнения'),
        installMeasure: string().required('ошибка заполнения'),
        stone: number().required('ошибка заполнения'), // камень закупка
        stoneValue: number().required('ошибка заполнения'),
        stoneMeasure: string().required('ошибка заполнения'),
        net: number().required('ошибка заполнения'), // себестоимость керамогранит, металлофото, эмаль
        netValue: number().required('ошибка заполнения'),
        netMeasure: string().required('ошибка заполнения'),
        polish: number().required('ошибка заполнения'), // з/п резка и полировка
        polishValue: number().required('ошибка заполнения'),
        polishMeasure: string().required('ошибка заполнения'),
        manager: number().required('ошибка заполнения'), // з/п менеджер
        managerValue: number().required('ошибка заполнения'),
        managerMeasure: string().required('ошибка заполнения'),
        implementer: number().required('ошибка заполнения'), // з/п исполнитель (создатель заказа)
        implementerValue: number().required('ошибка заполнения'),
        implementerMeasure: string().required('ошибка заполнения'),
    }),
    createdAt: date(),
    closedAt: mixed()
})


export type createOrderType = InferType<typeof createOrderShema>


export interface ICreateOrderLocal extends createOrderType {
    localID: string
}