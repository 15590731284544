<template>
    <div v-if="role === UserRole.ADMIN || role === UserRole.CHIEF_MANAGER || role === UserRole.MANAGER">
        <div class="order-list full-list" :class="{mt_80: props.margin}">
            <div class="order-list__title-box">
                <h2 class="order-list__title">Список заказов: {{ OrderType.gravesLandscaping }}</h2>
            </div>
            <div class="order-list__search mt-20">
                <h4>Поиск</h4>
                <form @submit.prevent="searchOrders">
                    <div class="order-list__search__params">
                        <div class="order-list__search__params__container" data-cnt="1">
                            <div class="order-list__search__container">
                                <div class="order-list__search__container__form-row">
                                    <input type="text" placeholder="Номер заказа" name="search" data-searchBy="number">
                                </div>
                            </div>
                            <v-select :options="searchOptions" label="title" placeholder="Выберите опции поиска"  @option:selected="selectSearchHandler">
                                <template #no-options="{ search, searching, loading }">
                                    Ничего не найдено.
                                </template>
                            </v-select>
                        </div>
                        <div class="order-list__search__params__container">
                            <div class="order-list__search__container">
                                <div class="order-list__search__container__form-row">
                                    <input type="text" placeholder="ФИО покойного" data-searchBy="information.deceased">
                                    <span>ФИО покойного</span>
                                </div>
                            </div>
                        </div>
                        <div class="order-list__search__params__container">
                            <div class="order-list__search__container">
                                <div class="order-list__search__container__form-row">
                                    <input type="text" placeholder="Год начала работ" data-searchBy="dates.startAt">
                                    <span>Год начала работ</span>
                                </div>
                            </div>
                        </div>
                        <div class="order-list__search__params__container">
                            <div class="order-list__search__container">
                                <div class="order-list__search__container__form-row">
                                    <input type="text" placeholder="Создатель заказа" data-searchBy="userId">
                                    <span>Создатель заказа</span>
                                </div>
                            </div>
                        </div>
                        <div class="order-list__search__params__container">
                            <div class="order-list__search__container">
                                <div class="order-list__search__container__form-row">
                                    <input type="text" placeholder="Кладбище" data-searchBy="information.cemetery">
                                    <span>Кладбище</span>
                                </div>
                            </div>
                        </div>
                        <div class="order-list__search__params__container">
                            <div class="order-list__search__container">
                                <div class="order-list__search__container__form-row">
                                    <input type="text" placeholder="Производимые работы" data-searchBy="services.title">
                                    <span>Производимые работы</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="order-list__search__container__submit-row">
                        <div class="order-list__search__container__submit-row__submit">
                            <input type="submit" value="Искать">
                        </div> 
                    </div>
                </form>
            </div>
            <!-- <div class="order-list__filter">
                <h4>Фильтр</h4>
                <form @submit.prevent="filterOrders">
                    <div class="order-list__filter__container">
                        <div class="order-list__filter__form-row">
                            <input type="number" placeholder="Год создания заказа" name="createdAt">
                        </div>
                        <div class="order-list__filter__submit-row">
                            <input type="submit" value="Применить" data-filterBy="createdAt">
                        </div>
                    </div>
                    <div class="order-list__filter__container">
                        <div class="order-list__filter__form-row">
                            <input type="number" placeholder="Год начала работ" name="dates.startAt">
                        </div>
                        <div class="order-list__filter__submit-row">
                            <input type="submit" value="Применить" data-filterBy="dates.startAt">
                        </div>
                    </div>
                </form>
            </div> -->
            <div class="order-list__sort-container">
                <div class="order-list__sort-box" v-if="ordersTable.sortable">
                    <h4>Сортировка</h4>
                    <div class="order-list__sort" v-if="ordersTable.sortable.sort === 'asc'">
                        <icon-component class="order-list__icon" name="sortUp" width="20" height="20"/>
                        <span class="order-list__sort-button">по возрастанию</span>
                    </div>
                    <div class="order-list__sort" v-else>
                        <icon-component class="order-list__icon" name="sortDown" width="20" height="20"/>
                        <span class="order-list__sort-button">по убыванию</span>
                    </div>
                </div>
                <div class="order-list__download">
                    <div class="order-list__download-box">
                        <icon-component class="order-list__icon" name="download" width="20" height="20"/>
                        <button class="order-list__download-box-button" @click.prevent="downloadOrders">Скачать</button>
                    </div>
                </div>  
            </div>

            <table-lite
                :is-slot-mode="true"
                :is-loading="ordersTable.isLoading"
                :columns="ordersTable.columns"
                :rows="ordersTable.rows"
                :rowClasses="tableRowClassHandler"
                :total="ordersTable.total"
                :sortable="sortable"
                :messages="ordersTable.messages"
                :pageOptions="ordersTable.pageOptions"
                @do-search="getOrders"
                @VnodeMounted="initTable"
            >
                <template v-slot:C1="ordersTable">
                    <v-select 
                        :appendToBody="true"
                        :searchable="false"
                        :autoscroll="false"
                        :modelValue="ordersTable.value.C1.status"
                        :options="statuses" 
                        @option:selected="(status: OrderStatus) => { setStatus(ordersTable.value.C1._id, status) }"
                        v-if="ordersTable.value.C1 !== '' && ( (role === UserRole.MANAGER && ordersTable.value.C1.userId === userId) || (role === UserRole.ADMIN || role === UserRole.CHIEF_MANAGER) )"
                    />
                    <span v-else>{{ ordersTable.value.C1.status }}</span>
                </template>
                <template v-slot:B1="ordersTable">
                    <div class="order-list__action-box" v-if="ordersTable.value.B1 !== ''">
                        <VDropdown>
                            <button class="order-list__action">
                                <icon-component class="order-list__icon" name="action" width="32" height="32"/>
                            </button>
                            <template #popper>
                                <div class="order-list__option-box">
                                    <router-link :to="{ name: 'order', params: { orderId: ordersTable.value.B1._id } }" target="_blank" class="order-list__option">Подробнее</router-link>
                                    <button class="order-list__option" @click="openPopup = ordersTable.value.B1._id">Платежи</button>
                                    <button class="order-list__option" v-if="role !== UserRole.MANAGER" @click="isDelete = ordersTable.value.B1._id">Перевести в архив</button>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <popup-component :mt="true" :isOpen="openPopup === ordersTable.value.B1._id" @close="openPopup = '', activePayment = false">
                        <template #title>Платежи</template>
                        <template #additional_title_info>
                            <span class="payments__title payments__title_gold">Номер заказа: {{ordersTable.value.B1.region.slug + ordersTable.value.B1.index}}</span>
                            <span class="payments__title payments__title_gold">ФИО покойного: {{ordersTable.value.B1?.information.deceased}}</span>
                        </template>
                        <template #content>
                            <div class="payments__information">
                                <div class="payments__information-item">
                                    <span class="payments__title">Цена</span>
                                    <span class="payments__text payments__text_np">{{ordersTable.value.B1.price.total}} руб</span>
                                </div>
                                <div class="payments__information-item">
                                    <span class="payments__title">Скидка</span>
                                    <span class="payments__text payments__text_np">{{ordersTable.value.B1.price.discount}} руб</span>
                                </div>
                                <div class="payments__information-item">
                                    <span class="payments__title">Цена со скидкой</span>
                                    <span class="payments__text payments__text_np">{{ordersTable.value.B1.price.final}} руб</span>
                                </div>
                                <div class="payments__information-item">
                                    <span class="payments__title">Оплачено</span>
                                    <span class="payments__text payments__text_np">{{ordersTable.value.B1.payments.reduce((sum: any, item: any) => {return sum + item.amount}, 0)}} руб</span>
                                </div>
                                <div class="payments__information-item">
                                    <span class="payments__title payments__title_gold">Остаток</span>
                                    <span class="payments__text payments__text_np">{{(ordersTable.value.B1.price.final - ordersTable.value.B1.payments.reduce((sum: any, item: any) => {return sum + item.amount}, 0)).toFixed(2)}} руб</span>
                                </div>
                            </div>
                            <ul class="payments">
                                <li class="payments__item">
                                    <span class="payments__text">№</span>
                                    <span class="payments__text">Платеж</span>
                                    <span class="payments__text payments__text_gray">Метод</span>
                                    <span class="payments__text">Дата</span>
                                </li>
                                <li class="payments__item" v-for="(item, index) in ordersTable.value.B1.payments">
                                    <span class="payments__text">{{index + 1}}</span>
                                    <span class="payments__text">{{`${item.amount} руб.`}}</span>
                                    <span class="payments__text payments__text_gray">{{item.method}}</span>
                                    <span class="payments__text">{{new Date(item.date).toLocaleDateString()}}</span>
                                </li>
                            </ul>
                            <div class="payments-box" v-if="+(ordersTable.value.B1.price.final - ordersTable.value.B1.payments.reduce((sum: any, item: any) => {return sum + item.amount}, 0)).toFixed(2) > 0">
                                <button-component buttonText="Добавить платеж" @click="activePayment = true" v-if="!activePayment && role !== UserRole.MANAGER"/>
                                <OrderPaymentsComponent :id="ordersTable.value.B1._id" @close="activePayment = false" @newPayment="setPayment" v-if="activePayment" :remains="+(ordersTable.value.B1.price.final - ordersTable.value.B1.payments.reduce((sum: any, item: any) => {return sum + item.amount}, 0)).toFixed(2)"/>
                            </div>
                        </template>
                    </popup-component>
                    <popup-component :mt="true" :isOpen="isDelete === ordersTable.value.B1._id" @close="isDelete = ''">
                        <template #title>
                            Вы хотите удалить заказ?
                        </template>
                        <template #content>
                            <div class="delete-box">
                                <button-component class="delete delete_success" buttonText="Да" @click="deleteOrder(ordersTable.value.B1._id)"/>
                                <button-component class="delete delete_close" buttonText="Нет" @click="isDelete = ''"/>
                            </div>
                        </template>
                    </popup-component>
                </template>
            </table-lite>
        </div>
    </div>
    <order-list-short-component v-if="role === 'Исполнитель'" :quantity="9999999"/>
</template>

<script setup lang="ts">
import buttonComponent from './buttonComponent.vue';
import OrderPaymentsComponent from './orderPaymentsComponent.vue';
import OrderListShortComponent from '../shared/orderListShortComponent.vue';
import PopupComponent from '../entities/popupComponent.vue'
import axios from '../app/lib/api';
import { IOrderFilter, IOrderFilterSearchBy, Order, OrderPayment, OrderStatus, OrderType, IOrderCostsAmount } from '../app/lib/types/orders';
import iconComponent from './iconComponent.vue';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { onMounted, ref, watch } from 'vue';
import toast from '../app/lib/hooks/errorToast';
import { omit } from 'lodash';
import { UserRole } from '../app/lib/types/users';
import { TOrdersTable } from '../app/lib/types/types';
import {useLoadingStore} from '../app/store/store';
import TableLite from 'vue3-table-lite/ts';
dayjs.extend(isBetween)

//https://github.com/linmasahiro/vue3-table-lite
//https://vue3-lite-table.vercel.app/usage/static-mode.html
const ordersTable = ref([] as any as TOrdersTable)
const sortable = {order: "A1", sort: "desc"}

const props = defineProps({
    margin: {type: Boolean},
    link: {type: Boolean},
    quantity: {type: Number, default: 0}
})

const loading = useLoadingStore()
const orders = ref([] as unknown as Order[])
const statuses = Object.values( omit(OrderStatus, 'archive'))
const role = localStorage.getItem("role")
const upOrders = ref(false)
const openPopup = ref("")
const isDelete = ref("")
const activePayment = ref(false)
const searchOptions = [
    {title: 'Номер заказа', searchBy: 'number'},
    {title: 'Год заключения', searchBy: 'createdAt'},
    {title: 'Год начала работ', searchBy: 'dates.startAt'},
    {title: 'ФИО заказчика', searchBy: 'information.client'},
    {title: 'Телефон', searchBy: 'information.clientPhone'},
    {title: 'ФИО покойного', searchBy: 'information.deceased'},
    {title: 'Год смерти', searchBy: 'information.deathDate'},
    {title: 'Кладбище', searchBy: 'information.cemetery'},
    {title: 'Статус', searchBy: 'status'},
    {title: 'Не оплачен', searchBy: 'notPaid', canBeEmpty: 'Y'},
    {title: 'Создатель заказа', searchBy: 'userId'},
    {title: 'Производимые работы', searchBy: 'services.title'},
    {title: 'Участок/ряд/место***', searchBy: ''},
    {title: 'Аванс/доплата***', searchBy: ''}
]
const userId = ref("")

watch(() => [upOrders], () => {},{ deep: true })

onMounted(() => {
    loading.loading()

    axios.users.getUserInfo()
    .then((res: any) => {
        userId.value = res.data.userId
    })

    let filter = {
        search: {
            searchBy: [{
                field: 'type',
                value: OrderType.gravesLandscaping
            }]
        }
    }

    axios.order.getOrderList(filter)
    .then((res: any) => {
        orders.value = res.data.result.filter((order: Order) => order.status !== OrderStatus.archive)
        prepareOrdersTable(res.data)
        loading.load()
        tableAmountHandler(filter)
    })
    .catch((err: any) => {
        loading.load()
        toast('error', "Не удалось загрузить заказы")
    })

    bindPageEvent();
})

/**
 * Обработка данных общих сумм и подстановка их в таблицу.
 */
function tableAmountHandler(filter: IOrderFilter) {
    axios.order.getOrdersAmount(filter)
    .then((res: any) => {
        const targetRow = document.querySelector('.vtl-table tbody tr:first-child')
        if(res.data.result.length > 0) {
            targetRow!.querySelector('td.L1 div span')!.innerHTML = res.data.result[0].priceFinal.toFixed(2) + ''
            targetRow!.querySelector('td.S1 div span')!.innerHTML = res.data.result[0].costsConcrete.toFixed(2) + ''
            targetRow!.querySelector('td.V1 div span')!.innerHTML = res.data.result[0].costsInstall.toFixed(2) + ''
            targetRow!.querySelector('td.W1 div span')!.innerHTML = res.data.result[0].debtAmount.toFixed(2) + ''
            targetRow!.querySelector('td.Z1 div span')!.innerHTML = res.data.result[0].revenue.toFixed(2) + ''   
        }
        else {
            if(targetRow) {
                let tdData = targetRow!.querySelectorAll('td.priceAmount div span')
                tdData!.forEach(el => {
                    el!.innerHTML = 'Нет данных'
                }) 
            }
        }
    })
    .catch((err: any) => {
        toast('warning', err)
        console.log(err)
    })
}

/**
 * Инициализация таблицы c заказами.
 */
const initTable = (tableComponent: any) => {
    bindTableEvents(tableComponent)
}

/**
 * Добавление событий для таблицы.
 */
function bindTableEvents(tableComponent: any) {
    // Свайп таблицы правой кнопкой мыши
    let table = tableComponent.el.querySelector('.vtl-table')
    let startX = table.scrollLeft
    table.addEventListener('mousedown', (event: MouseEvent) => {
        if( event.button === 2 ) {
            event.preventDefault()
            startX = table.scrollLeft + event.pageX
            table.addEventListener('mousemove', mouseMove)
            table.addEventListener('mouseup', (event: MouseEvent) => {
                table.removeEventListener('mousemove', mouseMove)
            })
        }
    })

    // Отключение для таблицы контекстного меню для правой кнопкой мыши
    table.addEventListener('contextmenu', (event: MouseEvent) => { event.preventDefault() })

    function mouseMove(event: MouseEvent) {
        table.scrollLeft = startX - event.pageX
    }
}

/**
 * Добавление событий на страницу.
 */
function bindPageEvent() {
    selectDeselectedHandler();
}

/**
 * Получение css-класса для строки в таблицы
 */
const tableRowClassHandler = (row: any) => {
    let rowClass = ''
    switch(row.C1.status) {
        case (OrderStatus.donePayment): rowClass = 'donePayment'; break
        case (OrderStatus.refusal): rowClass = 'refusal'; break
    }
    return rowClass;
}

/**
 * Скачивание таблицы с заказами.
 */
const downloadOrders = async () => {
    loading.loading()
    let filter = {
        useTableMap: false,
        perPage: 0,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        pageOffset: 0,
        search: {
            searchBy: [{
                field: 'type',
                value: OrderType.gravesLandscaping
            }]
        }
    }
    await axios.order.downloadOrders(filter)
    .then((r: any) => {
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'отчёт.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
        loading.load()
    })
    .catch((err: any) => {
        toast('error', "Скачивание не удалось: " + err)
    })
}

/**
 * Получение заказов в зависимости от пагинации
 */
async function getOrders (offset: number = 0, limit: number = 10, sortby: string = 'B1', sort: string = 'desc') {
  ordersTable.value.isLoading = true
  let filter = {
    useTableMap: true,
    perPage: limit,
    sortBy: sortby,
    sortOrder: sort,
    pageOffset: offset,
    search: {
        searchBy: getSearchByParams()
    }
  }
  axios.order.getOrderList(filter)
    .then((res: any) => {
        orders.value = res.data.result.filter((order: Order) => order.status !== OrderStatus.archive)
        prepareOrdersTable(res.data, sortby, sort)
        ordersTable.value.isLoading = false;
    })
    .catch((err: any) => {
        toast('error', "Не удалось загрузить заказы: " + err)
    })
}

/**
 * Поиск заказа.
 * @param e 
 */
async function searchOrders(e: any) {
    ordersTable.value.isLoading = true
    const searchByParams = getSearchByParams()
    if(searchByParams) {
        let filter = {
            search: {
                searchBy: searchByParams
            }
        }
        ordersTable.value.isLoading = true;
        axios.order.getOrderList(filter)
        .then((res: any) => {
            orders.value = res.data.result.filter((order: Order) => order.status !== OrderStatus.archive)
            prepareOrdersTable(res.data)
            ordersTable.value.isLoading = false
            tableAmountHandler(filter)
        })
        .catch((err: any) => {
            toast('error', "Не удалось найти заказ:" + err)
        })
    }
        
    else getOrders()
}

/**
 * Обработка выбора параметров поиска.
 * @param e 
 */
function selectSearchHandler(el: {title: string, searchBy: string, canBeEmpty: string}) {
    const searchInput = document.querySelector(`.order-list__search input[name="search"]`) as HTMLInputElement
    searchInput.value = ""
    searchInput.setAttribute('placeholder', el.title)
    searchInput.setAttribute('data-searchBy', el.searchBy)
    searchInput.setAttribute('data-canBeEmpty', el.canBeEmpty)
}

/**
 * Обработка сброса параметров поиска.
 */
function selectDeselectedHandler() {
    const deselect = document.querySelector('.order-list__search .vs__clear');
    if(typeof deselect != 'undefined' && deselect !== null) {
        deselect.addEventListener('click', () => {
            loading.loading()
            window.location.reload()
        })
    }
}

/**
 * Получение параметров поиска.
 */
function getSearchByParams() {
    const searchInputs:any = document.querySelectorAll(`.order-list__search__container__form-row input`);
    let searchByParams:IOrderFilterSearchBy[] = []
    
    searchInputs.forEach((searchInput: any , i: number) => {
        let searchBy = searchInput.getAttribute('data-searchBy') ? searchInput.getAttribute('data-searchBy') : ''
        if( (searchInput.value !== null && searchInput.value !== '') || searchInput.getAttribute('data-canBeEmpty') === 'Y' ) {
            switch(searchBy) {
                case 'notPaid':
                    searchByParams.push({
                        field: 'status',
                        value: '',
                        useRegEx: true,
                        in: [OrderStatus.processing, OrderStatus.active, OrderStatus.done]
                    },
                    {
                        field: 'type',
                        value: OrderType.gravesLandscaping
                    })
                    break
                default:
                    searchByParams.push({
                        field: searchBy,
                        value: searchInput.value.toString().trim(),
                        useRegEx: true
                    },
                    {
                        field: 'type',
                        value: OrderType.gravesLandscaping
                    })
                break
            }
        } else {
            searchByParams.push({
                field: 'type',
                value: OrderType.gravesLandscaping
            })
        }
    })


    // let searchByParams:IOrderFilterSearchBy[] = []
    // if(typeof(searchInput) != 'undefined' && searchInput !== null) {
    //     if( (searchInput.value !== null && searchInput.value !== '') || searchInput.getAttribute('data-canBeEmpty') === 'Y' ) {
    //         let searchBy = searchInput.getAttribute('data-searchBy') ? searchInput.getAttribute('data-searchBy') : ''
    //         switch(searchBy) {
    //             case 'notPaid':
    //                 searchByParams.push( {
    //                     field: 'status',
    //                     value: '',
    //                     useRegEx: true,
    //                     in: [OrderStatus.processing, OrderStatus.active, OrderStatus.done]
    //                 })
    //                 break
    //             default:
    //                 searchByParams.push( {
    //                     field: searchBy,
    //                     value: searchInput.value.toString().trim(),
    //                     useRegEx: true
    //                 } )
    //             break
    //         }
    //     }
    // }
    return searchByParams
}

// /**
//  * Фильтрация заказов.
//  */
// async function filterOrders(e: any) {
//     ordersTable.value.isLoading = true
//     const filterForm = new FormData(e.target)
//     const filterBy = e.submitter.getAttribute('data-filterBy')

//     let searchBy = { field: '', value: ''}
//     for(let [name, value] of filterForm) {
//         if( name === filterBy ) {
//             searchBy = {field: name, value: value.toString()}
//             break
//         }
//     }

//     if( searchBy.value !== '' ) {
//         let filter = {
//             search: {
//                 searchBy: searchBy
//             }
//         }

//         // Очистка формы
//         for(let [name, value] of filterForm) {
//             if(name !== searchBy.field) {
//                 let clearedInput: any = document.querySelector(`.order-list__filter__form-row input[name="${name}"]`)
//                 if(clearedInput !== null) clearedInput.value =''
//             }
//         }

//         ordersTable.value.isLoading = true;
//         axios.order.getOrderList(filter)
//         .then((res: any) => {
//             orders.value = res.data.result.filter((order: Order) => order.status !== OrderStatus.archive)
//             prepareOrdersTable(res.data)
//             ordersTable.value.isLoading = false
//         })
//         .catch((err: any) => {
//             toast('error', "Не удалось найти заказ: " + err)
//         })
//         // for(let [name, value] of filterForm) {
//         //     console.log(`${name} = ${value}`);
//         // } 
//     }

//     else getOrders()
// }

/**
 * Подготовка данных для вставку в основную таблицу
 * @param data 
 */
async function prepareOrdersTable(data: any, order?: string, sort?: string) {
    ordersTable.value.columns = [
        {label: "№ заказа", field: "A1", headerClasses: ["A1"], isKey: true, sortable: true},
        {label: "Статус", field: "B1", headerClasses: ["B1"], columnClasses: ["B1"]},
        {label: "", field: "C1"},
        {label: "Дата заключения", field: "D1", sortable: true},
        {label: "Дата начала работ", field: "DD", sortable: true, headerClasses: ["DD"]},
        {label: "ФИО заказчика", field: "E1"},
        {label: "Телефон", field: "F1"},
        {label: "ФИО покойного", field: "G1"},
        {label: "Дата смерти", field: "H1", sortable: true},
        {label: "Кладбище", field: "I1"},
        {label: "Участок/ряд/место", field: "J1"},
        {label: "Создатель заказа", field: "K1"},
        {label: "Общая сумма заказа", field: "L1", sortable: true, headerClasses: ["L1"], columnClasses: ["L1", "priceAmount"]},
        {label: "ЦПА+ расходник", field: "M1"},
        {label: "Цена бетонных работ", field: "N1"},
        {label: "Себестоимость ограды+скамейка+стол+плитка+крошка", field: "O1", headerClasses: ["O1"]},
        {label: "Производимые работы", field: "P1", headerClasses: ["P1", "wide"]},
        {label: "Аванс, доплата", field: "Q1", headerClasses: ["Q1", "semi-wide"]},
        {label: "Дата внесения денег", field: "R1", headerClasses: ["R1", "semi-wide"]},
        {label: "МАП (20%) бетон", field: "S1", headerClasses: ["S1"] , columnClasses: ["S1", "priceAmount"]},
        {label: "Выезд", field: "T1"},
        {label: "Бригада", field: "U1"},
        {label: "Оплата установщикам", field: "V1", headerClasses: ["V1"], columnClasses: ["V1", "priceAmount"]},
        {label: "Сумма долга", field: "W1", headerClasses: ["W1"], columnClasses: ["W1", "priceAmount"]},
        {label: "Выручка", field: "Z1", headerClasses: ["Z1"], columnClasses: ["Z1", "priceAmount"]},
    ]

    ordersTable.value.isLoading = false
    ordersTable.value.total = data.params.count
    ordersTable.value.sortable = { order: order, sort: sort }
    ordersTable.value.rows = []
    ordersTable.value.messages = {
        pageSizeChangeLabel: "Показывать по",
        pagingInfo: "Всего найдено: {2}",
        gotoPageLabel: "Перейти к",
        noDataAvailable: "Данных не найдено"
    },
    ordersTable.value.pageOptions = [
        {value: 10, text: "10"},
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 999999999999, text: "Все"}
    ]

    // TODO Переделать на всплывающие окна
    // Добавление строки с технической информацией
    ordersTable.value.rows.push({
        A1: '', 
        B1: '', 
        C1: '',
        D1: '',
        DD: '',
        E1: '',
        F1: '',
        G1: '',
        H1: '',
        I1: '',
        J1: '',
        K1: '',
        L1: 'Загрузка...',
        M1: '',
        N1: '',
        O1: '',
        P1: '',
        Q1: '',
        R1: '',
        S1: 'Загрузка...',
        T1: '',
        U1: '',
        V1: 'Загрузка...',
        W1: 'Загрузка...',
        Z1: 'Загрузка...'
    })
    data.result.forEach((el: any, i: number) => {
        ordersTable.value.rows.push(
            {
                A1: el.number,
                B1: el,
                C1: el,
                D1: new Date(el.createdAt).toLocaleDateString() + '', 
                DD: new Date(el.dates.startAt).toLocaleDateString() + '', 
                E1: el.information.client,
                F1: el.information.clientPhone,
                G1: el.information.deceased,
                H1: typeof el.information.deathDate !== 'undefined' ? new Date(el.information.deathDate).toLocaleDateString() : '---',
                I1: el.information.cemetery,
                J1: el.information.graveDistrict + '/' + el.information.graveRow + '/' + el.information.gravePlace,
                K1: el.creatorContacts.fullname,
                L1: el.price.final.toFixed(2) + '\u00a0\u20bd',
                M1: el.costs.raw.toFixed(2) + '\u00a0\u20bd',
                N1: (el.price.final - el.costs.raw).toFixed(2) + '\u00a0\u20bd',
                O1: el.costs.materials.toFixed(2) + '\u00a0\u20bd',
                P1: el.services.map( (service: any) => '~ ' + service.title + '\u00a0' + service.price + '\u00a0\u20bd').join('\r\n'),
                Q1: el.payments.map( (payment: any) => '~ ' + payment.amount + ' \u00a0\u20bd').join('\r\n'),
                R1: el.payments.map( (payment: any) => '-> ' + new Date(payment.date).toLocaleDateString()).join('\r\n'),
                S1: el.costs.concrete.toFixed(2) + '\u00a0\u20bd',
                T1: el.costs.departure.toFixed(2) + '\u00a0\u20bd',
                U1: el.costs.brigade.toFixed(2) + '\u00a0\u20bd',
                V1: el.costs.install.toFixed(2) + '\u00a0\u20bd',
                W1: el.price.final - el.payments.reduce((summ: number, payment: any) => summ + payment.amount, 0) + '\u00a0\u20bd',
                Z1: (el.price.final - el.costs.materials - el.costs.concrete - el.costs.install).toFixed(2) + '\u00a0\u20bd'
            }
        )
    })
}

async function setStatus(id: string, status: OrderStatus) {
    try {
        ordersTable.value.isLoading = true
        await axios.order.updateOrderStatus(id, status)
        const order = orders.value.find(order => order._id === id )
        if(order){
            order.status = status
        }
        ordersTable.value.isLoading = false
    } catch (error: any) {
        loading.load()
        toast("error", error.response.data.message)
    }
}

/**
 * Добавление данных о платеже.
 * @param data 
 */
function setPayment(data: {id: string, payment: OrderPayment}) {
    const order = orders.value.find(order => order._id === data.id)
    if(order){
        order.payments.push(data.payment)
    }
}

/**
 * Удаление заказа.
 * @param id 
 */
async function deleteOrder(id: string) {
    try {
        loading.loading()
        await axios.order.updateOrderStatus(id, OrderStatus.archive)
        orders.value = orders.value.filter(order => order._id !== id)
        loading.load()
    } catch (error: any) {
        toast("error", error.response.data.message)
        loading.load()
    }
}
</script>

<style lang="sass">
    /* Dropdown Menu */
    :root
        --vs-dropdown-min-width: 110px
    .mt_80
        margin-top: 80px
    .mt-20
        margin-top: 20px
    h4 
        margin: 4px 0
    .v-popper--theme-dropdown
        .v-popper__inner
            border: none
            border-radius: 0
    .payments
        display: block
        &-box
            margin-top: 30px
        &__information
            display: grid
            grid-template-columns: repeat(5, 1fr)
            gap: 20px
            @media (max-width: 915px)
                grid-template-columns: repeat(3, 1fr)
            @media (max-width: 600px)
                grid-template-columns: repeat(2, 1fr)
            @media (max-width: 440px)
                grid-template-columns: 100%
            &-item
                display: flex
                flex-direction: column
                align-items: center
                gap: 10px
        &__title
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--brown)
            display: block
            &_gold
                color: var(--gold)
        &__item
            margin-top: 20px
            display: grid
            grid-template-columns: repeat(4, 200px)
            gap: 30px
            padding-bottom: 10px
            border-bottom: 1px solid var(--gray)
            @media (max-width: 1020px)
                grid-template-columns: repeat(2, 200px)
            @media (max-width: 560px)
                grid-template-columns: 280px
        &__text
            display: block
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--brown)
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            padding: 10px
            &_gray
                color: var(--gray)
            &_np
                padding: 0
    .order-list.full-list
        max-width: 100%
        width: 100%
        .order-list
            &__sort-container
                display: flex
                justify-content: space-between
                align-items: end
            &__title
                display: block
                font-family: 'Roboto'
                font-weight: 500
                font-size: 32px
                color: var(--brown)
                &-box
                    width: 100%
                    display: flex
                    align-items: center
                    flex-wrap: wrap
                    gap: 30px
                    margin-bottom: 40px
            &__link
                display: block
                font-family: 'Roboto'
                font-weight: 400
                font-size: 20px
                color: var(--gold)
                text-decoration: underline
            &__sort
                display: flex
                align-items: center
                flex-wrap: wrap
                gap: 10px
                background: linear-gradient(180deg, #ddc18d, transparent)
                border-radius: 4px
                padding: 4px
                &-box
                    display: flex
                    align-items: center
                    gap: 40px
                    margin-bottom: 4px
                    @media (max-width: 500px)
                        flex-wrap: wrap
                &-button
                    display: block
                    background: none
                    border: none
                    font-family: 'Roboto'
                    font-weight: 400
                    font-size: 16px
                    color: var(--brown)
            &__icon
                display: block
                color: var(--brown)
            &__list
                display: block
                margin-top: 30px
                width: 100%
            &__item
                width: 100%
                display: flex
                align-items: center
                padding: 24px 30px
                border-bottom: 1px solid var(--gray-light)
                &:hover
                    @media (min-width: 540px)
                        background-color: var(--gold-light)
            &__content
                display: flex
                align-items: center
                flex-wrap: wrap
                .vs__dropdown-toggle
                    width: 150px
                    height: 30px
                    border: none
                .vs__search
                    //display: none
                .vs__actions
                    flex: none
                .vs__clear
                    display: none
            &__text
                font-family: 'Roboto'
                font-weight: 400
                font-size: 18px
                color: var(--brown)
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                &_gray
                    color: var(--gray)
            &__action
                border: none
                background: none
                &-box
                    margin-left: auto
            &__option
                display: block
                border: none
                background: none
                font-family: 'Roboto'
                font-weight: 600
                font-size: 14px
                color: var(--brown)
                &:not(:first-child)
                    margin-top: 20px
                &-box
                    width: 200px
                    padding: 20px
                    border-radius: 3px
            &__name
                width: 280px
            &__index
                width: 160px
            &__type
                width: 217px
            &__id
                width: 90px
            &__status
                width: 140px
            &__download-box
                display: flex
                margin-bottom: 5px
                &-button
                    display: block
                    font-family: "Roboto"
                    font-weight: 600
                    font-size: 18px
                    border: none
                    border-radius: 3px
                    background: none
                    pointer-events: auto
                    background-color: var(--gold)
                    color: var(--white)
                    padding: 15px 20px
                    transition: 0.3s ease-in-out
                    transition-property: background-color
                    width: 140px
                    &:hover
                        cursor: pointer
                        background-color: var(--brown)
            &__search
                form
                    display: flex
                    flex-direction: column
                    margin-bottom: 40px
                    .order-list__search__params
                        &__container
                            display: flex
                            margin-bottom: 16px
                    .v-select
                        position: relative
                        font-family: inherit
                        width: 250px
                        border: 1px solid #d3d3d3
                        display: flex
                        justify-content: center
                        border-radius: 4px
                        margin-left: 8px
                        .vs__dropdown-toggle
                            width: 100%
                        .vs__actions
                            cursor: pointer
                            width: 50px
                        .vs__search
                            cursor: pointer
                &__container:nth-child(1)
                    display: flex
                    align-items: baseline
                &__container:nth-child(2)
                    margin-top: -18px
                    margin-left: 20px
                &__container
                    &__params
                        &__button
                            padding: 10px
                            cursor: pointer
                        &__list
                            opacity: 0
                            pointer-events: none
                            position: absolute
                            background: #fbfbfb
                            z-index: 1000
                            li
                                padding: 10px
                                cursor: pointer
                    &__form-row
                        input
                            padding: 14px
                            border: none
                            border-radius: 3px
                            background-color: var(--gray-light)
                            color: var(--gray-dark)
                            font-family: "Roboto"
                            font-weight: 400
                            font-size: 18px
                            height: 100%
                            width: 320px
                        span
                            margin-left: 8px
                    &__submit-row
                        input
                            display: block
                            font-family: "Roboto"
                            font-weight: 600
                            font-size: 18px
                            border: none
                            border-radius: 3px
                            background: none
                            pointer-events: auto
                            background-color: var(--gold)
                            color: var(--white)
                            padding: 12px 36px
                            transition: 0.3s ease-in-out
                            transition-property: background-color
                            width: 320px
                            &:hover
                                cursor: pointer
                                background-color: var(--brown)
            &__filter
                margin-bottom: 40px
                margin-top: 40px
                form
                    display: flex
                    flex-direction: column
                &__container
                    display: flex
                    margin-top: 10px
                &__form-row
                    input
                        padding: 14px
                        border: none
                        border-radius: 3px
                        background-color: var(--gray-light)
                        color: var(--gray-dark)
                        font-family: "Roboto"
                        font-weight: 400
                        font-size: 18px
                        height: 100%
                &__submit-row
                    input
                        display: block
                        font-family: "Roboto"
                        font-weight: 600
                        font-size: 18px
                        border: none
                        border-radius: 3px
                        background: none
                        pointer-events: auto
                        background-color: var(--gold)
                        color: var(--white)
                        padding: 15px 20px
                        transition: 0.3s ease-in-out
                        transition-property: background-color
                        width: 140px
                        &:hover
                            cursor: pointer
                            background-color: var(--brown)
    .vtl    
        .vtl-table
            tr
                &:hover
                    cursor: grab
            tr:first-child
                td.priceAmount div span
                    background: #bbf9d1
                    border-radius: 4px
                    padding: 4px
            tr.donePayment
                background-color: #feffcd !important
            tr.refusal
                background-color: #ff9090 !important
            td
                white-space: pre-wrap
            th.A1
                div
                    width: 140px
            th.BB
                div
                    width: 110px
            th.J1
                div
                    width: 150px
            th.M1
                div
                    width: 130px
            th.N1
                div
                    width: 330px
            th.O1
                div
                    width: 80px
            th.P1
                div
                    width: 100px
            th.Q1
                div
                    width: 150px
            th.T1
                div
                    width: 150px
            th.U1
                div
                    width: 150px
            th.V1
                div
                    width: 150px
            th.W1
                div
                    width: 100px
            th.semi-wide
                div
                    min-width: 200px
            th.wide
                div
                    min-width: 350px
            .v-select
                .vs__actions
                    display: none
                .vs__dropdown-menu
                    width: 110px
                    min-width: 110px
                .vs__selected-options
                    display: block
                    text-align: center
                    .vs__selected
                        display: inline-block
                        position: relative
                    .vs__search
                        cursor: pointer
                        background: linear-gradient(180deg, #ddc18d, transparent)
                        border: none
                        background: inherit
                        width: 0
                        padding: 0
                        margin: 0
                        flex-grow: 0
                .vs__dropdown-toggle
                    cursor: pointer
                    background: linear-gradient(180deg, #ddc18d, transparent)
                
        .vtl-paging-change-div
            display: flex
            align-items: baseline
            justify-content: space-evenly
    .delete
        padding: 12px 28px
        border-width: 2px
        border-style: solid
        &-box
            display: flex
            justify-content: space-between
        &_success
            border-color: var(--green)
            color: var(--green)
        &_close
            border-color: var(--red)
            color: var(--red)
</style>