<template >
    <div page-card>
        <h2 v-if="order.type === 'Благоустройство могил'" class="create-order__title">Наряд-заказ на благоустройство могилы <b>{{ order.number }}</b></h2>
        <h2 v-if="order.type === 'Памятник'" class="create-order__title">Наряд-заказ на гранитное изделие <b>{{ order.number }} {{ order.letter }}</b></h2>
        <span class="create-order__required">* — поля, обязательные для заполнения</span>
        <form action="" type="POST" enctype="multipart/form-data" class="create-order__form">
            <div class="order-information__item information">
                <div class="information__list">
                    <VDropdown
                    :distance="6"
                    :disabled="disabled || updateOrderFields.information.manager != ''"
                    >
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.manager" inputType="text" inputName="manager" inputContent="Иванов Иван Иванович" inputLabel="Менеджер" />
                        <template #popper>
                            <div class="information__select">
                                <button class="information__select-item" v-for="item in getNestingList(managers)" @click="setManager(item)">{{item}}</button>
                            </div>
                        </template>
                    </VDropdown>
                    <input-component v-if="order.type === 'Памятник'" :disabled="disabled" v-model:meaning="updateOrderFields.letter" inputType="text" inputName="letter" inputContent="А" inputLabel="Буквенное обозначение выставки" :required=true />
                </div>
            </div>
            <div class="order-information__item information">
                <h3 class="order-information__title_min">Даты заказа</h3>
                <div class="information__list">
                    <div class="information__item">
                        <span class="information__title">Дата заключения договора</span>
                        <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.createdAt" :enable-time-picker="false" @update:model-value="changeOrderCreatedAt" locale="ru">
                            <template #calendar-header="{ index, day }">
                                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                    {{ day }}
                                </div>
                            </template>
                        </Datepicker>
                    </div>
                    <div class="information__item">
                        <span class="information__title">Дата закрытия договора</span>
                        <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.closedAt" :enable-time-picker="false" @update:model-value="changeOrderClosedAt" locale="ru">
                            <template #calendar-header="{ index, day }">
                                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                    {{ day }}
                                </div>
                            </template>
                        </Datepicker>
                    </div>
                </div>
            </div>
            <div class="create-order__item information">
                <h3 class="create-order__title_min">Контактная информация</h3>
                <div class="information__list">
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.client" @input="errors.informationclient= ''" inputType="text" inputName="customer" inputContent="Иванов Иван Иванович" inputLabel="Ф.И.О. заказчика" :required=true :inputError="errors.informationclient" />
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.clientPhone" @input="errors.informationclientPhone= ''" inputType="tel" inputName="phone" inputContent="+7 (999) 999-99-99" inputLabel="Номер телефона" :required=true :inputError="errors.informationclientPhone" />
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.clientAddress" inputType="text" inputName="adress" inputContent="г. Великий Новгород, ул. Луговая, 7" inputLabel="Адрес" />
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.clientEmail" inputType="email" inputName="email" inputContent="email@mail.ru" inputLabel="Email"/>
                </div>
            </div>
            <div class="create-order__item information">
                <h3 class="create-order__title_min">Информация о покойном</h3>
                <div class="information__list">
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.deceased" @input="errors.informationdeceased= ''" inputType="text" inputName="deceased" inputContent="Петров Пётр Петрович" inputLabel="Ф.И.О. покойного" :required=true :inputError="errors.informationdeceased" />
                    <div class="information__date-box">
                        <span class="information__date-title field__title">Дата рождения <b>*</b></span>
                        <Datepicker :disabled="disabled" class="information__date" placeholder="Укажите дату" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.information.birthDate" :enable-time-picker="false" locale="ru">
                            <template #calendar-header="{ index, day }">
                                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                  {{ day }}
                                </div>
                              </template>
                        </Datepicker>
                    </div>
                    <div class="information__date-box">
                        <span class="information__date-title field__title">Дата смерти <b>*</b></span>
                        <Datepicker :disabled="disabled" class="information__date" placeholder="Укажите дату" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.information.deathDate" :enable-time-picker="false" locale="ru">
                            <template #calendar-header="{ index, day }">
                                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                  {{ day }}
                                </div>
                              </template>
                        </Datepicker>
                    </div>
                    <VDropdown
                    :distance="6"
                    :disabled="disabled || updateOrderFields.information.cemetery != ''"
                    @click="errors.informationcemetery= ''"
                    >
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.cemetery" @input="errors.informationcemetery= ''" inputType="text" inputName="cemetery" inputContent="Укажите/введите кладбище" inputLabel="Место установки" :required=true :inputError="errors.informationcemetery" />
                        <template #popper>
                            <div class="information__select">
                                <button class="information__select-item" v-for="item in cityData.params.cemetery" @click="setCemetery(item.name)">{{item.name}}</button>
                            </div>
                        </template>
                    </VDropdown>
                    <div class="information__list_sub">
                        <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.graveDistrict" @input="errors.informationgraveDistrict= ''" inputType="text" inputName="district" inputContent="квадрат" inputLabel="Квадрат" :required=true :inputError="errors.informationgraveDistrict" />
                        <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.graveRow" @input="errors.informationgraveRow= ''" inputType="text" inputName="row" inputContent="№ ряда" inputLabel="Ряд" :required=true :inputError="errors.informationgraveRow" />
                        <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.gravePlace" @input="errors.informationgravePlace= ''" inputType="text" inputName="place" inputContent="№ места" inputLabel="Место" :required=true :inputError="errors.informationgravePlace" />
                    </div>
                </div>
            </div>
            <div v-if="order.type === 'Благоустройство могил'" class="create-order__item service">
                <h3 class="create-order__title_min">Список оказываемых услуг</h3>
                <div class="service__list">
                    <div class="service__item" v-for="service, index in updateOrderFields.services" :key="service.title">
                        <buttonComponent class="service__delete" buttonText="Удалить услугу" :reverseIcon=true iconSize="20" iconName="delete" @click.prevent="removeOrderService(service.title)" />
                        <span class="service__index">{{index + 1}}</span>
                        <span class="service__title">Наименование услуги</span>
                        <v-select :disabled="disabled" v-model="service.title" placeholder="Ограда" :options="serviceNameList" @option:selected="resetToZero(service)" />
                        <div class="service__parametrs">
                            <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="100" inputLabel="Размер"/>
                            <input-component :disabled="disabled" v-model:meaning="service.measurement" inputType="text" inputName="measurement" inputContent="п/м" inputLabel="Ед. изм."/>
                            <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                            <input-component :disabled="disabled" v-model:meaning="service.price" @input="service.quantity ? service.cost =+ ((service.price / service.quantity).toFixed(2)) : ''" inputType="number" inputName="price" inputContent="1600" inputLabel="Сумма, руб."/>
                        </div>
                    </div>
                </div>
                <button-component button-text="+ Добавить услугу" class="create-order__button" @click.prevent="addOrderService()"/>
            </div>
            <div v-if="order.type === 'Памятник'" class="create-order__item service">
                <h3 class="create-order__title_min">Список оказываемых услуг</h3>
                <div v-for="servicesType, key in servicesTypes" :key="servicesType">
                    <div class="create-order__service-devider">{{key + 1}} - {{servicesType}}</div>
                    <div class="service__list">
                        <div class="service__item" v-for="service, index in getCurrentServiceType(servicesType)" :key="service.title">
                            <buttonComponent :disabled="disabled" class="service__delete" buttonText="Удалить услугу" :reverseIcon=true iconSize="20" iconName="delete" @click.prevent="removeOrderMonumentService(service.type ? service.type : '', index)" />
                            <span class="service__index">{{index + 1}}</span>
                            <span class="service__title">Наименование услуги</span>
                            <v-select :disabled="disabled" v-model="service.title" placeholder="Услуга" :options="getServices(key)" @option:selected="resetToZero(service)" />
                            <div v-if="service.title === 'Памятник'">
                                <div class="service__parametrs">
                                    <div class="field">
                                        <span class="field__title">Цвет</span>
                                        <v-select :disabled="disabled" v-model="service.color" placeholder="Цвет" :options="getNestingList(color)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.monumentNumber" inputType="text" inputName="monumentNumber" inputContent="Номер памятника в каталоге" inputLabel="Номер памятника в каталоге" />
                                    <div class="field">
                                        <VDropdown
                                        :distance="6"
                                        :disabled="disabled"
                                        >
                                        <input-component :disabled="disabled" v-model:meaning="service.size" inputType="text" inputName="size" inputContent="100x200x300" inputLabel="Размер (ШxВxГ)" />
                                            <template #popper>
                                                <div class="information__select">
                                                    <button class="information__select-item" v-for="item in getNestingList(sizesMonument)" @click="service.size = item">{{item}}</button>
                                                </div>
                                            </template>
                                        </VDropdown>
                                    </div>
                                    <div class="field">
                                        <span class="field__title">Полировка</span>
                                        <v-select :disabled="disabled" v-model="service.polish" placeholder="Полировка" :options="getNestingList(polish)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Подставка' || service.title === 'Цветник'">
                                <div class="service__parametrs">
                                    <div class="field">
                                        <span class="field__title">Цвет</span>
                                        <v-select :disabled="disabled" v-model="service.color" placeholder="Цвет" :options="getNestingList(color)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.size" inputType="text" inputName="size" inputContent="100x200x300" inputLabel="Размер (ШxВxГ)" />
                                    <div class="field">
                                        <span class="field__title">Полировка</span>
                                        <v-select :disabled="disabled" v-model="service.polish" placeholder="Полировка" :options="getNestingList(polish)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Надгробная плита'">
                                <div class="service__parametrs">
                                    <div class="field">
                                        <span class="field__title">Цвет</span>
                                        <v-select :disabled="disabled" v-model="service.color" placeholder="Цвет" :options="getNestingList(color)" />
                                    </div>
                                    <div class="field">
                                        <VDropdown
                                        :distance="6"
                                        :disabled="disabled"
                                        >
                                        <input-component :disabled="disabled" v-model:meaning="service.size" inputType="text" inputName="size" inputContent="100x200x300" inputLabel="Размер (ШxВxГ)" />
                                            <template #popper>
                                                <div class="information__select">
                                                    <button class="information__select-item" v-for="item in getNestingList(sizesTombstone)" @click="service.size = item">{{item}}</button>
                                                </div>
                                            </template>
                                        </VDropdown>
                                    </div>
                                    <div class="field">
                                        <span class="field__title">Полировка</span>
                                        <v-select :disabled="disabled" v-model="service.polish" placeholder="Полировка" :options="getNestingList(polish)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Ваза' || service.title === 'Подсвечник' || service.title === 'Фото ниша под керамогранит'">
                                <div class="service__parametrs">
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.size" inputType="text" inputName="size" inputContent="100x200x300" inputLabel="Размер (ШxВxГ)" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Техническое отверстие'">
                                <div class="service__parametrs">
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Фаска'">
                                <div class="service__parametrs">
                                    <div class="field">
                                        <span class="field__title">Тип фаски</span>
                                        <v-select :disabled="disabled" v-model="service.chamfer" placeholder="Фаска" :options="getNestingList(chamferNesting)" />
                                    </div>
                                    <div class="field">
                                        <span class="field__title">Полировка</span>
                                        <v-select :disabled="disabled" v-model="service.polish" placeholder="Полировка" :options="getNestingList(chamferPolish)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Иное гранитное изделие'">
                                <div class="service__parametrs">
                                    <input-component :disabled="disabled" v-model:meaning="service.anotherMonument" inputType="text" inputName="anotherMonument" inputContent="Название изделия" inputLabel="Название изделия"/>
                                    <input-component :disabled="disabled" v-model:meaning="service.size" inputType="text" inputName="size" inputContent="100x200x300" inputLabel="Размер (ШxВxГ)" />
                                    <div class="field">
                                        <span class="field__title">Полировка</span>
                                        <v-select :disabled="disabled" v-model="service.polish" placeholder="Полировка" :options="getNestingList(polish)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Шрифт' || service.title === 'Пескоструйные изображения'">
                                <div class="service__parametrs">
                                    <input-component :disabled="disabled" v-model:meaning="service.sandblastingNumber" inputType="text" inputName="sandblastingNumber" inputContent="Номер в пескоструйном каталоге" inputLabel="Номер в пескоструйном каталоге" />
                                    <input-component v-if="service.title === 'Пескоструйные изображения'" :disabled="disabled" v-model:meaning="service.sandblastingPageNumber" inputType="text" inputName="sandblastingPageNumber" inputContent="Страница в пескоструйном каталоге" inputLabel="Страница в пескоструйном каталоге" />
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Крест' && service.type === 'Пескоструйные работы'">
                                <div class="service__parametrs">
                                    <input-component :disabled="disabled" v-model:meaning="service.sandblastingNumber" inputType="text" inputName="sandblastingNumber" inputContent="Номер в пескоструйном каталоге" inputLabel="Номер в пескоструйном каталоге" />
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Покраска'">
                                <div class="service__parametrs">
                                    <div class="field">
                                        <span class="field__title">Цвет</span>
                                        <v-select :disabled="disabled" v-model="service.color" placeholder="Цвет" :options="getNestingList(paintingNesting)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Эпитафия'">
                                <div class="service__parametrs">
                                    <input-component :disabled="disabled" v-model:meaning="service.epitaph" inputType="text" inputName="epitaph" inputContent="Номер в каталоге / Иное" inputLabel="Номер в каталоге / Иное" />
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.font" inputType="text" inputName="font" inputContent="Шрифт" inputLabel="Шрифт" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Портрет'">
                                <div class="service__parametrs">
                                    <div class="field">
                                        <span class="field__title">Тип портрета</span>
                                        <v-select :disabled="disabled" v-model="service.portrait" placeholder="Тип портрета" :options="getNestingList(portraitNesting)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Ретушь'">
                                <div class="service__parametrs">
                                    <div class="field">
                                        <span class="field__title">Тип ретуши</span>
                                        <v-select :disabled="disabled" v-model="service.portrait" placeholder="Тип ретуши" :options="getNestingList(retouchNesting)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Цветы' || service.title === 'Свечи' || service.title === 'Пейзаж'">
                                <div class="service__parametrs">
                                    <input-component :disabled="disabled" v-model:meaning="service.artworkNumber" inputType="text" inputName="artworkNumber" inputContent="Номер в художественном каталоге" inputLabel="Номер в художественном каталоге" />
                                    <input-component :disabled="disabled" v-model:meaning="service.artworkPageNumber" inputType="text" inputName="artworkPageNumber" inputContent="Страница в художественном каталоге" inputLabel="Страница в художественном каталоге" />
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Крест' && service.type === 'Художественные работы'">
                                <div class="service__parametrs">
                                    <input-component :disabled="disabled" v-model:meaning="service.artworkNumber" inputType="text" inputName="artworkNumber" inputContent="Номер в художественном каталоге" inputLabel="Номер в художественном каталоге" />
                                    <input-component :disabled="disabled" v-model:meaning="service.artworkPageNumber" inputType="text" inputName="artworkPageNumber" inputContent="Страница в художественном каталоге" inputLabel="Страница в художественном каталоге" />
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Иное изображение'">
                                <div class="service__parametrs">
                                    <input-component :disabled="disabled" v-model:meaning="service.anotherArtwork" inputType="text" inputName="anotherArtwork" inputContent="Название" inputLabel="Название" />
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <input-component :disabled="disabled" v-model:meaning="service.anotherArtworkRetouch" inputType="text" inputName="anotherArtworkRetouch" inputContent="Ретушь" inputLabel="Ретушь" />
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Макет памятника'">
                                <div class="service__parametrs">
                                    <div class="field">
                                        <span class="field__title">Макет памятника</span>
                                        <v-select :disabled="disabled" v-model="service.monumentModel" placeholder="Макет памятника" :options="getNestingList(monumentModelNesting)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity ? service.price = service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                </div>
                            </div>
                            <div v-if="service.title === 'Керамогранит' || service.title === 'Металлофото' || service.title === 'Табличка эмаль \\ фото эмаль'">
                                <div class="service__parametrs">
                                    <input-component :disabled="disabled" v-model:meaning="service.size" inputType="text" inputName="size" inputContent="100x200x300" inputLabel="Размер (ШxВxГ)" />
                                    <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.cost && service.quantity && service.modelPrice ? service.price = (service.cost * service.quantity) + service.modelPrice : service.cost && service.quantity ? service.price =  service.cost * service.quantity : ''" inputType="number" inputName="quantity" inputContent="Количество" inputLabel="Количество" />
                                    <div v-if="service.title === 'Табличка эмаль \\ фото эмаль'" class="field">
                                        <span class="field__title">Крепеж</span>
                                        <v-select :disabled="disabled" v-model="service.fastener" placeholder="Крепеж" :options="getNestingList(yesOrNo)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.cost && service.quantity && service.modelPrice ? service.price = (service.cost * service.quantity) + service.modelPrice : service.cost && service.quantity ? service.price =  service.cost * service.quantity : service.price = service.cost * 1" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                                    <input-component :disabled="disabled" v-model:meaning="service.price" inputType="number" inputName="price" inputContent="16000" inputLabel="Цена" />
                                    <div v-if="service.title === 'Керамогранит'" class="field">
                                        <span class="field__title">Макет керамогранита</span>
                                        <v-select :disabled="disabled" v-model="service.porcelainModel" placeholder="Макет керамогранита" :options="getNestingList(yesOrNo)" />
                                    </div>
                                    <div v-if="service.title === 'Металлофото'" class="field">
                                        <span class="field__title">Макет металлофото</span>
                                        <v-select :disabled="disabled" v-model="service.metalPhoto" placeholder="Макет металлофото" :options="getNestingList(yesOrNo)" />
                                    </div>
                                    <div v-if="service.title === 'Табличка эмаль \\ фото эмаль'" class="field">
                                        <span class="field__title">Макет эмали</span>
                                        <v-select :disabled="disabled" v-model="service.enamelModel" placeholder="Макет эмали" :options="getNestingList(yesOrNo)" />
                                    </div>
                                    <input-component :disabled="disabled" v-model:meaning="service.modelPrice" @input="service.cost && service.quantity && service.modelPrice ? service.price = (service.cost * service.quantity) + service.modelPrice : service.cost && service.quantity ? service.price =  service.cost * service.quantity : service.price =  service.cost * service.quantity" inputType="number" inputName="modelPrice" inputContent="16000" inputLabel="Цена за макет" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="service__summary">
                        <button-component :disabled="disabled" button-text="+ Добавить услугу" class="create-order__button" @click.prevent="addOrderService(servicesType)"/>
                        <div class="service__summary_box">
                            <span class="service__summary_name">Итого ({{servicesType}})</span>
                            <span class="service__summary_sum">{{getCurrentTypePrice(servicesType)}} руб.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="create-order__item additional-service">
                <h3 class="create-order__title_min">Дополнительные услуги</h3>
                <textarea :disabled="disabled" class="additional-service__field" v-model="updateOrderFields.moreServices" placeholder="Описание дополнительных услуг"></textarea>
            </div>
            <div class="create-order__item payment">
                <div class="col">
                    <div class="cost">
                        <h3 class="create-order__title_min">Расчёт стоимости</h3>
                        <div class="cost__list">
                            <div class="col">
                                <div class="payment__value-box">
                                    <span class="payment__label">Сумма заказа</span>
                                    <div class="payment__value">{{new Intl.NumberFormat('ru-RU').format(updateOrderFields.price.total)}} руб.</div>
                                </div>
                            </div>
                            <div class="col" v-if="updateOrderFields.price.total > 0">
                                <div class="payment__value-box">
                                    <span class="payment__label">Тип оплаты</span>
                                    <div class="payment__value payment__value_min min">{{prepay === 0 ? 'без предоплаты' : remains === 0 ? 'полный расчёт' : 'авансовый платёж'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="discount">
                        <div class="col">
                            <div class="payment__value-box">
                                <span class="payment__label">Скидка</span>
                                <div class="payment__value payment__value_min min">{{order.price.discountValue}} {{order.price.discountMeasure}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="payment__value-box">
                                <span class="payment__label">Размер аванса</span>
                                <div class="payment__value payment__value_min min">{{new Intl.NumberFormat('ru-RU').format(prepay)}} руб.</div>
                            </div>
                        </div>
                    </div>
                    <div class="create-order__item pay">
                        <div class="col">
                            <div class="payment__value-box">
                                <span class="payment__label payment__label_big">К оплате</span>
                                <div class="payment__value payment__value_gold">{{new Intl.NumberFormat('ru-RU').format(updateOrderFields.price.final)}} руб.</div>
                            </div>
                            <div class="payment__value-box payment__value_mt">
                                <span v-if="order.payments[0]" class="payment__label">Способ оплаты</span>
                                <div class="payment__value payment__value_min min">{{order.payments[0]?.method}}</div>
                            </div>
                        </div>
                        <div class="col" v-if="order.payments[0]">
                            <div class="payment__value-box">
                                <span class="payment__label">Остаток</span>
                                <div class="payment__value">{{new Intl.NumberFormat('ru-RU').format(updateOrderFields.price.final - prepay)}} руб.</div>
                            </div>
                        </div>
                    </div>
                    <button-component class="create-order__submit" @click.prevent="updateOrder" button-text="Обновить заказ"/>
                </div>
                <div class="col">
                    <h3 class="create-order__title_min">Сроки выполнения работ</h3>
                    <div class="create-order__date">
                        <div class="col">
                            <span class="payment__label">Начало работ <b>*</b></span>
                            <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.dates.startAt" :enable-time-picker="false" @update:model-value="changeWorkDateStart" locale="ru">
                                <template #calendar-header="{ index, day }">
                                    <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                      {{ day }}
                                    </div>
                                  </template>
                            </Datepicker>
                        </div>
                        <div class="col">
                            <span class="payment__label">Окончание работ <b>*</b></span>
                            <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.dates.endAt" :enable-time-picker="false" @update:model-value="changeWorkDateEnd" locale="ru">
                                <template #calendar-header="{ index, day }">
                                    <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                      {{ day }}
                                    </div>
                                  </template>
                            </Datepicker>
                        </div>
                    </div>
                    <div class="create-order__item create-order__comment">
                        <div class="create-order__item additional-service">
                            <h3 class="create-order__title_min">Комментарий к заказу</h3>
                            <textarea :disabled="disabled" class="additional-service__field" v-model="updateOrderFields.comment" placeholder="Текст комментария"></textarea>
                        </div>
                    </div>
                    <div class="order-information__item order-information__upload">
                        <div class="order-information__item additional-service uploaded-files">
                            <h3 class="order-information__title_min">Прикреплённые файлы</h3>
                            <label class="create-order__file">
                                <input type="file" name="uploadFiles" class="create-order__file-input" @change="getFile" multiple accept="image/*">
                                <span class="create-order__file-button">
                                    <icon-component class="order-card__icon" :width='"20"' :height='"20"' :name='"link-alt"'/>
                                    Прикрепить файл
                                </span>
                            </label>
                            <div v-if="updateOrderFields.uploadFiles" v-for="image, key in updateOrderFields.uploadFiles" :key="key">
                                <span v-if="image" class="create-order__file-text" title="Удалить файл" @click="deleteFile($event, image.name)">{{ image.name }} (Удалить)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script setup lang="ts">
import InputComponent from '../shared/Form/Input/InputComponent.vue'
import buttonComponent from '../shared/buttonComponent.vue'
import { RadioInput } from '../shared/Form/Radio'
import FieldTemplate from '../shared/Field/FieldTemplate.vue'
import iconComponent from '../shared/iconComponent.vue'
import graniteServices from '../app/lib/json/graniteServices.json'
import sandblastingServices from '../app/lib/json/sandblastingServices.json'
import artworkServices from '../app/lib/json/artworkServices.json'
import photoServices from '../app/lib/json/photoServices.json'
import color from '../app/lib/json/granite/color.json'
import polish from '../app/lib/json/granite/polish.json'
import chamferNesting from '../app/lib/json/granite/chamferNesting.json'
import chamferPolish from '../app/lib/json/granite/chamferPolish.json'
import paintingNesting from '../app/lib/json/sandblasting/paintingNesting.json'
import monumentModelNesting from '../app/lib/json/artwork/monumentModelNesting.json'
import portraitNesting from '../app/lib/json/artwork/portraitNesting.json'
import retouchNesting from '../app/lib/json/artwork/retouchNesting.json'
import yesOrNo from '../app/lib/json/photo/yesOrNo.json'
import listService from '../app/lib/json/serviceList.json'
import managers from '../app/lib/json/managers.json'
import sizesMonument from '../app/lib/json/sizesMonument.json'
import sizesTombstone from '../app/lib/json/sizesTombstone.json'
import toast from "../app/lib/hooks/errorToast"
import axios from '../app/lib/api'
import { OrderService, PaymentMeasure, Order, OrderFiles } from '../app/lib/types/orders'
import { computed, onMounted, PropType, ref, watch, watchEffect } from 'vue'
import { updateOrderShema } from '../app/lib/shemas/updateOrderShema'
import { useRoute } from 'vue-router'
import router from '../app/router/router'
import {useLoadingStore} from '../app/store/store'
import { Cities } from '../app/lib/types/cities'
import { cloneDeep } from 'lodash'

const cityData = ref({} as Cities)
const servicesTypes = ['Гранитное изделие', 'Пескоструйные работы', 'Художественные работы', 'Фото']

const emit = defineEmits({
    updateOrder: null
})

const props = defineProps({
    order: {
        type: Object as PropType<Order>,
        required: true
    }
})

const prepay =  props.order?.payments.reduce((sum, item) => {return sum + item.amount}, 0)

const remains: number | undefined = props.order?.price.final - prepay

const loading = useLoadingStore()
const updateOrderFields = ref({
    letter: "",
    information: {
        client: "",
        clientPhone: "",
        clientAddress: "",
        clientEmail: "",
        deceased: "" ,
        birthDate: new Date() as Date | undefined,
        deathDate: new Date() as Date | undefined,
        cemetery: "" ,
        graveDistrict: "" ,
        graveRow: "" ,
        gravePlace: "" ,
        manager: "",
    },
    services: [] as OrderService[],
    moreServices: "",
    price: {
        total: 0,
        discountValue: 0,
        discountMeasure: PaymentMeasure.percent,
        discount: 0,
        final: 0,
    },
    dates: {
      startAt: new Date(),
      endAt: new Date(),
    },
    comment: "" as string | undefined,
    uploadFiles: [] as OrderFiles[] | undefined,
    signImage: "" ,
    costs: {
        concrete: 0,
        concreteValue: 0,
        concreteMeasure: '',
        materials: 0,
        materialsValue: 0,
        materialsMeasure: '',
        departure: 0,
        departureValue: 0,
        departureMeasure: '',
        brigade: 0,
        brigadeValue: 0,
        brigadeMeasure: '',
        raw: 0,
        rawValue: 0,
        rawMeasure: '',
        install: 0,
        installValue: 0,
        installMeasure: '',
        stone: 0,
        stoneValue: 0,
        stoneMeasure: '',
        net: 0,
        netValue: 0,
        netMeasure: '',
        polish: 0,
        polishValue: 0,
        polishMeasure: '',
        manager: 0,
        managerValue: 0,
        managerMeasure: '',
        implementer: 0,
        implementerValue: 0,
        implementerMeasure: '',
    },
    createdAt: new Date(),
    closedAt: new Date()
})
const errors = ref({
    informationclient: "",
    informationclientPhone: "",
    informationdeceased: "",
    informationbirthDate: "",
    informationdeathDate: "",
    informationcemetery: "",
    informationgraveDistrict: "",
    informationgraveRow: "",
    informationgravePlace: "",
    datesstartAt: "",
    datesendAt: "",
})
const addServiceList = ref([] as string[])
const discount = ref(true)
const disabled = ref(false)
const measure = Object.values(PaymentMeasure).map((item) => {
    return {
        label: item,
        value: item,
    }
})
const route = useRoute()

let formData = new FormData()

function getFile(event: any) {
    for (const file of event.target.files) {
        updateOrderFields.value.uploadFiles?.push({
            name: file.name,
            path: '/',
            delete: false
        })
        formData.append('uploadFiles', file)
    }
}

const deleteFile = (event: Event, fileName: string) => {
    updateOrderFields.value.uploadFiles?.map((file) => {
        if (file.name === fileName) {
            file.delete = true
        }
    })
    //@ts-ignore
    event.target.style.display = 'none'
    alert('Файл будет удален!')
}

onMounted( () => {
    loading.loading()
    axios.cities.getCurrentCity()
    .then((city: any) => {
        loading.load()
        cityData.value = city.data
    })
    .catch((err: any) => {
        loading.load()
        toast('error', err + ' Не удалось получить информацию по городу')
    })
} )

function addOrderService(type?: string) {
    let service = {
        type: type,
        title: "",
        measurement: "",
        quantity: 0,
        cost: 0,
        color: "",
        monumentNumber: "",
        size: "",
        polish: "",
        chamfer: "",
        anotherMonument: "",
        sandblastingNumber: "",
        sandblastingPageNumber: "",
        epitaph: "",
        font: "",
        portrait: "",
        retouch: "",
        artworkNumber: "",
        artworkPageNumber: "",
        anotherArtwork: "",
        anotherArtworkRetouch: "",
        monumentModel: "",
        porcelainModel: "",
        metalPhoto: "",
        fastener: "",
        enamelModel: "",
        modelPrice: 0,
        price: 0,
    }
    updateOrderFields.value.services.push(service)
}

function removeOrderService(title: string) {
    updateOrderFields.value.services = updateOrderFields.value.services.filter((item) =>  item.title !== title )
}

function removeOrderMonumentService(type: string, index: number) {
    const el = getCurrentServiceType(type)?.at(index)
    //@ts-ignore
    const elIndex = updateOrderFields.value.services?.indexOf(el)
    //@ts-ignore
    return updateOrderFields.value.services?.splice(elIndex, 1)
}

function setCemetery(item: string) {
    updateOrderFields.value.information.cemetery = item
}

function setManager(item: string) {
    updateOrderFields.value.information.manager = item
}

function resetToZero(service: OrderService) {
    service.measurement = listService.find(item => item.name === service.title)?.measurement || ""
    service.quantity = 0
    service.cost = 0
    service.color = ""
    service.monumentNumber = ""
    service.size = ""
    service.polish = ""
    service.chamfer = ""
    service.anotherMonument = ""
    service.sandblastingNumber = ""
    service.sandblastingPageNumber = ""
    service.epitaph = ""
    service.font = ""
    service.portrait = ""
    service.retouch = ""
    service.artworkNumber = ""
    service.artworkPageNumber = ""
    service.anotherArtwork = ""
    service.anotherArtworkRetouch = ""
    service.monumentModel = ""
    service.porcelainModel = ""
    service.metalPhoto = ""
    service.fastener = ""
    service.enamelModel = ""
    service.modelPrice = 0
    service.price = 0
}

function getCurrentServiceType(type: string) {
    return updateOrderFields.value.services?.filter((item) => item.type === type)
}

function getServices(type: number) {
    switch (type) {
        case 0:
            return [...graniteServices].map(service => service.name)
        case 1:
            return [...sandblastingServices].map(service => service.name)
        case 2:
            return [...artworkServices].map(service => service.name)
        case 3:
            return [...photoServices].map(service => service.name)
    }
}

function getNestingList(nesting: any[]) {
    return [...nesting].map(service => service.name)
}

function getCurrentTypePrice(type: string) {
    let price = 0
    getCurrentServiceType(type)?.forEach((item) => {
        price += item.price
    })
    return new Intl.NumberFormat('ru-RU').format(price)
}

/**
 * Обновление данных заказа.
 */
async function updateOrder() {
    try {
        await updateOrderShema.validate(updateOrderFields.value, { abortEarly: false })
        errors.value = {
            informationclient: "",
            informationclientPhone: "",
            informationdeceased: "",
            informationbirthDate: "",
            informationdeathDate: "",
            informationcemetery: "",
            informationgraveDistrict: "",
            informationgraveRow: "",
            informationgravePlace: "",
            datesstartAt: "",
            datesendAt: "",
        }
        try {
            loading.loading()
            const rawValue = cloneDeep(updateOrderFields.value)
            formData.append('data', JSON.stringify(rawValue))
            //@ts-ignore
            await axios.order.updateOrder(route.params.orderId as string, formData)
            loading.load()
            toast('success', "Заказ успешно обновлен")
            emit("updateOrder", {
                updateOrderFields: updateOrderFields
            })
            router.go(0)
        } catch (error: any) {
            loading.load()
            toast('error', error)
        }
    } catch (error: any) {
        console.log(error)
        error.inner.forEach((error: any) => {
            // @ts-ignore
            errors.value[error.path.replace( /\./g, '' )] = error.message;
            if (error.path.includes("service")) {
                toast('error', "выберите услугу")
            }
        });
        if(errors.value.datesstartAt != "") {
            toast('error', errors.value.datesstartAt)
        }
        if(errors.value.datesendAt != "") {
            toast('error', errors.value.datesendAt)
        }
    }
}

/**
 * Обновление даты начала работ.
 */
const changeWorkDateStart = (modelData: Date) => {
    updateOrderFields.value.dates.startAt = modelData
}

/**
 * Обновление даты завершения работ.
 */
const changeWorkDateEnd = (modelData: Date) => {
    updateOrderFields.value.dates.endAt = modelData
}

/**
 * Обновление даты заключения договра.
 */
const changeOrderCreatedAt = (modelData: Date) => {
    updateOrderFields.value.createdAt = modelData
}

/**
 * Обновление даты закрытия договора.
 */
const changeOrderClosedAt = (modelData: Date) => {
    updateOrderFields.value.closedAt = modelData
}

const serviceNameList = computed(() => {
    const usedNames = updateOrderFields.value.services.map(service => service.title)
    return [...listService].filter(service => !usedNames.includes(service.name)).map(service => service.name)

})

const discountPrice = computed(() => updateOrderFields.value.price.final = updateOrderFields.value.price.total - updateOrderFields.value.price.discount)

watch(() => updateOrderFields.value.services, (newVal:typeof updateOrderFields.value.services) => {
    updateOrderFields.value.price.total = newVal.reduce<number>((old, curr) => {
        return old +  +curr.price
    }, 0);
}, {deep: true})

watchEffect(() => {
    if(updateOrderFields.value.price.discountMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.price.discount  = (updateOrderFields.value.price.discountValue * updateOrderFields.value.price.total) / 100
    } else {
        updateOrderFields.value.price.discount  = updateOrderFields.value.price.discountValue
    }
})
watch(() => [updateOrderFields.value.price.discount, updateOrderFields.value.price.total], () => {
    updateOrderFields.value.price.final = updateOrderFields.value.price.total - updateOrderFields.value.price.discount
})

watch(() => [updateOrderFields.value.price.final], ()=> {
    if(updateOrderFields.value.costs.rawMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.raw = ( updateOrderFields.value.costs.rawValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.materialsMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.materials = ( updateOrderFields.value.costs.materialsValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.concreteMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.concrete = ( updateOrderFields.value.costs.concreteValue * (updateOrderFields.value.price.final - updateOrderFields.value.costs.raw - updateOrderFields.value.costs.materials)) / 100
    }
    if(updateOrderFields.value.costs.installMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.install = ( updateOrderFields.value.costs.installValue * (updateOrderFields.value.price.final - updateOrderFields.value.costs.raw - updateOrderFields.value.costs.materials)) / 100
    }
    if(updateOrderFields.value.costs.brigadeMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.brigade = ( updateOrderFields.value.costs.brigadeValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.departureMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.departure = ( updateOrderFields.value.costs.departureValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.stoneMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.stone = ( updateOrderFields.value.costs.stoneValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.netMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.net = ( updateOrderFields.value.costs.netValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.polishMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.polish = ( updateOrderFields.value.costs.polishValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.managerMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.manager = ( updateOrderFields.value.costs.managerValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.implementerMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.implementer = ( updateOrderFields.value.costs.implementerValue * updateOrderFields.value.price.final) / 100
    }
})

function maxDiscountInput(value: number) {
    if(updateOrderFields.value.price.discountMeasure === PaymentMeasure.percent) {
        if(value > 100) {
            updateOrderFields.value.price.discountValue = 100
        } else {
            updateOrderFields.value.price.discountValue = value
        }
    } else {
        if(value > updateOrderFields.value.price.total) {
            updateOrderFields.value.price.discountValue = updateOrderFields.value.price.total
        } else {
            updateOrderFields.value.price.discountValue = value
        }
    }
}

watch(() => [updateOrderFields.value.price.discountMeasure], () => {
    if(updateOrderFields.value.price.discountMeasure === PaymentMeasure.percent) {
        maxDiscountInput(updateOrderFields.value.price.discountValue)
    }
})

onMounted(() => {
    //@ts-ignore
    updateOrderFields.value.letter = props.order.letter
    //@ts-ignore
    updateOrderFields.value.information = props.order.information
    updateOrderFields.value.services = props.order.services
    updateOrderFields.value.moreServices = props.order.moreServices
    updateOrderFields.value.price = props.order.price
    updateOrderFields.value.dates = props.order.dates
    updateOrderFields.value.comment = props.order.comment
    updateOrderFields.value.uploadFiles = props.order.uploadFiles
    updateOrderFields.value.costs = props.order.costs
    updateOrderFields.value.dates.startAt = props.order.dates.startAt
    updateOrderFields.value.dates.endAt = props.order.dates.endAt
    updateOrderFields.value.createdAt = props.order.createdAt
    updateOrderFields.value.closedAt = props.order.closedAt
})
</script>

<style lang="sass">
    .create-order
        &__title
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            color: var(--brown)
            &_min
                font-family: 'Roboto'
                font-weight: 500
                font-size: 24px
                color: var(--brown)
            b
                color: var(--gold)
        &__required
            display: block
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--red)
            margin-top: 10px
        &__item
            margin-top: 60px
        &__button
            display: flex
            gap: 5px
            padding: 12px 28px
            border: 2px solid var(--gold)
            color: var(--gold)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 14px
            margin-top: 30px
        &__submit
            background: var(--gold)
            padding: 14px 30px
            max-width: 386px
            width: 100%
            margin-top: 60px
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--white)
        &__remove
            margin-top: 60px
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--gray)
            text-decoration: underline
        &__date
            display: grid
            grid-template-columns: repeat(2, calc( 50% - 30px ))
            gap: 30px
            margin-top: 30px
            &-item
                margin-top: 14px
            @media (max-width: 450px)
                grid-template-columns: 100%
    .information
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(3, 4fr)
            gap: 30px
            @media (max-width: 1200px)
                grid-template-columns: repeat(2, 6fr)
            @media (max-width: 1200px)
                grid-template-columns: 100%
            &_sub
                display: grid
                grid-template-columns: repeat(3, 120px)
                gap: 13px
                @media (max-width: 430px)
                    grid-template-columns: 100%
        &__select
            max-width: 360px
            width: 100%
            padding: 20px
            &-item
                display: block
                background: none
                width: 100%
                border: none
                color: var(--brown)
                font-size: 18px
                &:not(:first-child)
                    margin-top: 20px
        &__date
            width: 100%
            margin-top: 10px
            .dp__input
                padding: 12px 12px
                border: 1px solid var(--dp-background-color)
            .dp__input_icon_pad
                padding-left: 35px
            &-box
                width: 100%
            &-title
                color: var(--brown)
                font-family: "Roboto"
                font-weight: 300
                font-size: 18px
    .service
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(3, 4fr)
            gap: 30px
            @media (max-width: 1450px)
                grid-template-columns: repeat(2, 4fr)
                gap: 30px
            @media (max-width: 990px)
                grid-template-columns: 100%
                gap: 30px
        &__item
            width: 100%
            padding: 20px
            border: 2px solid var(--gold)
            .vs__dropdown-toggle
                width: 100%
                height: 48px
                border: none
                background-color: var(--gray-light)
                margin-top: 10px
                padding: 14px
                align-items: center
            .vs__search
                &::placeholder
                    color: var(--gray)
            .vs__actions
                flex: none
            .vs__clear
                display: none
        &__delete
            padding: 5px 10px
            width: 100%
            border: 1px solid var(--gray)
            border-radius: 3px
            background: none
            display: flex
            justify-content: center
            align-items: center
            gap: 10px
            font-family: 'Roboto'
            font-weight: 500
            font-size: 14px
            color: var(--gray)
        &__title
            display: block
            margin-top: 30px
            color: var(--brown)
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
        &__index
            display: block
            width: 100%
            margin-top: 10px
            padding: 5px
            text-align: center
            background-color: var(--brown)
            color: var(--white)
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            border-radius: 3px
        &__checklist
            margin-top: 30px
        &__parametrs
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(2, calc( 50% - 15px ))
            gap: 30px
            @media (max-width: 400px)
                grid-template-columns: 100%
    .additional-service
        &__field
            width: 100%
            height: 150px
            margin-top: 20px
            padding: 14px
            display: block
            resize: none
            background: var(--gray-light)
            border: none
            border-radius: 3px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            &::placeholder
                color: var(--gray)
    .payment
        display: grid
        grid-template-columns: repeat(2, 6fr)
        gap: 30px
        @media (max-width: 1230px)
            display: flex
            flex-direction: column-reverse
            width: 100%
        &__label
            display: block
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--brown)
            b
                color: var(--red)
            &_big
                font-family: 'Roboto'
                font-weight: 500
                font-size: 20px
                color: var(--brown)
        &__value
            margin-top: 15px
            font-family: 'Roboto'
            font-weight: 500
            font-size: 24px
            color: var(--brown)
            &_gold
                font-size: 28px
                color: var(--gold)
            &_flex
                display: flex
                gap: 16px
                align-items: start
            &_mt
                margin-top: 30px
    .cost
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(2, 200px)
            gap: 30px
            @media (max-width: 450px)
                grid-template-columns: 100%
    .radio
        &__list
            margin-top: 14px
            flex: none
        &__item
            display: flex
            align-items: center
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--gray-dark)
            input
                width: 20px
                height: 20px
                margin-right: 8px
            &:not(:first-child)
                margin-top: 14px
    .discount
        margin-top: 30px
        display: grid
        grid-template-columns: repeat(2, 200px)
        gap: 30px
        @media (max-width: 450px)
                grid-template-columns: 100%
    .pay
        margin-top: 30px
        display: grid
        grid-template-columns: repeat(2, 200px)
        gap: 30px
        @media (max-width: 450px)
                grid-template-columns: 100%
    .v-popper__arrow-container
        display: none
    .uploaded-files
        display: flex
        flex-wrap: wrap
        align-items: center
        gap: 30px
        & h3
            flex: 1 0 100%
        & *:not(h3)
            margin: 0
            cursor: pointer
    .signature
        &-box
            border: 1px solid var(--gray)
            border-radius: 5px
            overflow: hidden
            position: relative
        &__undo
            height: 20px
            &-box
                position: absolute
                top: 5px
                right:5px
                z-index: 2
                border: none
                background: none
        &__button
            text-transform: uppercase
            color: var(--white)
            background: var(--gold)
            width: 100%
            padding: 13px 10px
            &_gray
                background: none
                color: var(--gray)
                border: 1px solid var(--gray)
                margin-top: 20px
            &-box
                width: 100%
                margin-top: 20px
</style>